/* eslint-disable max-lines */
//
//  SDKDataCore.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2020 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTOCreateDocumentationGroupInput,
  DTOCreateDocumentationPageInputV2,
  DTOCreateDocumentationTabInput,
  DTODeleteDocumentationGroupInput,
  DTODeleteDocumentationPageInputV2,
  DTODeleteDocumentationTabGroupInput,
  DTODesignElementsDataDiffResponse,
  DTODocumentationLinkPreviewRequest,
  DTODocumentationPageAnchor,
  DTODocumentationPageApprovalStateChangeInput,
  DTODuplicateDocumentationGroupInput,
  DTODuplicateDocumentationPageInputV2,
  DTOElementActionInput,
  DTOExporterPropertyValueMap,
  DTOFigmaNode,
  DTOFigmaNodeRenderIdInput,
  DTOFigmaNodeRenderInput,
  DTOFigmaNodeV2,
  DTOMoveDocumentationGroupInput,
  DTOMoveDocumentationPageInputV2,
  DTOPipelineCreateBody,
  DTOPipelineUpdateBody,
  DTOPublishDocumentationRequest,
  DTORestoreDocumentationGroupInput,
  DTORestoreDocumentationPageInput,
  DTOUpdateDocumentationGroupInput,
  DTOUpdateDocumentationPageInputV2,
} from "@supernova-studio/client"
import {
  DesignSystemInvite,
  DesignSystemInviteUpdate,
  DesignSystemMemberUpdate,
  DesignSystemPendingMemberInvite,
  DesignSystemUserInvitation,
} from "@supernova-studio/model"

import {
  DesignSystemAccessMode,
  DocsBlockDefinition,
  ExportBuildStatus,
  ExporterJobDestination,
  ResourceFont,
  TokenThemeOverrideTranserObject,
  TokenUtils,
  UserRole,
} from "../../exports"
import { Acl } from "../../model/acl/SDKAcl"
import { Asset } from "../../model/assets/SDKAsset"
import { RenderedAsset } from "../../model/assets/SDKRenderedAsset"
import { BootstrapData } from "../../model/base/SDKBootstrapData"
import { Brand, BrandUpdateModel } from "../../model/base/SDKBrand"
import {
  DesignSystem,
  DesignSystemSwitcher,
} from "../../model/base/SDKDesignSystem"
import { DesignSystemCollection } from "../../model/base/SDKDesignSystemCollection"
import { DesignSystemContactList } from "../../model/base/SDKDesignSystemContactList"
import { DesignSystemMemberList } from "../../model/base/SDKDesignSystemMemberList"
import {
  DesignSystemVersion,
  DesignSystemVersionCreate,
  DesignSystemVersionCreationJob,
  DesignSystemVersionUpdateModel,
} from "../../model/base/SDKDesignSystemVersion"
import { DesignSystemVersionStats } from "../../model/base/SDKDesignSystemVersionStats"
import {
  DocumentationLegacy,
  DocumentationLegacyModel,
} from "../../model/base/SDKDocumentationLegacy"
import { Component } from "../../model/components/SDKComponent"
import { DesignComponent } from "../../model/components/SDKDesignComponent"
import { DesignComponentVariant } from "../../model/components/SDKDesignComponentVariant"
import { DocsEntityGroupModel } from "../../model/docs/entities/SDKDocsEntityGroup"
import { DocsEntityPageModel } from "../../model/docs/entities/SDKDocsEntityPage"
import { ElementDataView } from "../../model/elements/SDKElementDataView"
import { ElementDataViewColumn } from "../../model/elements/SDKElementDataViewColumn"
import {
  ElementProperty,
  ElementPropertyCreationModel,
  ElementPropertyTargetElementType,
  ElementPropertyUpdateModel,
} from "../../model/elements/SDKElementProperty"
import { ElementPropertyValue } from "../../model/elements/values/SDKElementPropertyValue"
import { ConnectionProviders } from "../../model/enums/SDKConnectionProviders"
import { DocumentationEnvironment } from "../../model/enums/SDKDocumentationEnvironment"
import { TokenType } from "../../model/enums/SDKTokenType"
import {
  AzurePersonalAccessTokenConnectionPayload,
  PersonalAccessTokenConnectionPayload,
} from "../../model/exporters/SDKConnections"
import {
  Exporter,
  ExporterProvider,
  NewExporterPayload,
} from "../../model/exporters/SDKExporter"
import { Pipeline } from "../../model/exporters/SDKPipeline"
import { ExporterCustomBlock } from "../../model/exporters/custom_blocks/SDKExporterCustomBlock"
import { ExporterCustomBlockVariant } from "../../model/exporters/custom_blocks/SDKExporterCustomBlockVariant"
import { ExporterConfigurationProperty } from "../../model/exporters/custom_properties/SDKExporterConfigurationProperty"
import { ExportBuild } from "../../model/exporters/exporter_builds/SDKExportBuild"
import { ExportBuildResultLog } from "../../model/exporters/exporter_builds/SDKExportBuildResults"
import { ConnectionRemoteModel } from "../../model/exporters/remote/SDKRemoteConnectionsModel"
import { AssetGroup } from "../../model/groups/SDKAssetGroup"
import { ComponentGroup } from "../../model/groups/SDKComponentGroup"
import { DesignComponentGroup } from "../../model/groups/SDKDesignComponentGroup"
import { TokenGroup } from "../../model/groups/SDKTokenGroup"
import { ImportJob } from "../../model/jobs/SDKImportJob"
import { Membership } from "../../model/membership/SDKMembership"
import { ResourceAsset } from "../../model/resources/SDKResourceAsset"
import { CustomDomain } from "../../model/support/SDKCustomDomain"
import { FigmaStructure } from "../../model/support/SDKFigmaStructure"
import { FigmaStructureDetail } from "../../model/support/SDKFigmaStructureDetail"
import { Source, SourceRemoteModelCloud } from "../../model/support/SDKSource"
import { TokenTheme } from "../../model/themes/SDKTokenTheme"
import { Token } from "../../model/tokens/SDKToken"
import { InspectableUser } from "../../model/users/SDKInspectableUser"
import { User } from "../../model/users/SDKUser"
import { UserMembership } from "../../model/users/SDKUserMembership"
import { UserProfileUpdateModel } from "../../model/users/SDKUserProfile"
import {
  UserWorkspaceNotificationSettings,
  UserWorkspaceNotificationSettingsUpdateModel,
} from "../../model/users/SDKUserWorkspaceNotificationSettings"
import { Workspace } from "../../model/workspaces/SDKWorkspace"
import {
  WorkspaceIPWhitelistSettings,
  WorkspaceIPWhitelistSettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceIPWhitelistSettings"
import {
  WorkspaceIntegration,
  WorkspaceIntegrations,
  WorkspaceIntegrationsRemoteModel,
  WorkspaceIntegrationType,
} from "../../model/workspaces/SDKWorkspaceIntegration"
import { WorkspaceIntegrationAccessTokenPayload } from "../../model/workspaces/SDKWorkspaceIntegrationAccessToken"
import {
  GitProviderEndpoints,
  WorkspaceIntegrationGetGitProvidersInput,
} from "../../model/workspaces/SDKWorkspaceIntegrationGetGitProviders"
import {
  OAuth2IntegrationTypes,
  WorkspaceIntegrationOauthRequest,
  WorkspaceIntegrationOauthRequestRemoteModel,
} from "../../model/workspaces/SDKWorkspaceIntegrationOauthRequest"
import {
  WorkspaceInvitation,
  WorkspaceInvitationUpdateModel,
} from "../../model/workspaces/SDKWorkspaceInvitation"
import { WorkspaceInvoice } from "../../model/workspaces/SDKWorkspaceInvoice"
import { WorkspaceNPMRegistryAccessTokenRemoteModel } from "../../model/workspaces/SDKWorkspaceNPMRegistryAccessToken"
import {
  WorkspaceNPMRegistrySettings,
  WorkspaceNPMRegistrySettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceNPMRegistrySettings"
import {
  WorkspaceOnboarding,
  WorkspaceOnboardingUpdateModel,
} from "../../model/workspaces/SDKWorkspaceOnboarding"
import { WorkspaceProduct } from "../../model/workspaces/SDKWorkspaceProduct"
import { SubscriptionProductFeatures } from "../../model/workspaces/SDKWorkspaceProductFeatures"
import { WorkspaceProfileUpdateModel } from "../../model/workspaces/SDKWorkspaceProfile"
import {
  WorkspaceSSOSettings,
  WorkspaceSSOSettingsUpdateModel,
} from "../../model/workspaces/SDKWorkspaceSSOSettings"
import {
  WorkspaceSubscription,
  WorkspaceSubscriptionUpdateModel,
} from "../../model/workspaces/SDKWorkspaceSubscription"
import {
  GitProviderBranchesRemoteModel,
  GitProvidersBranches,
} from "../../model/workspaces/integrations/GitProvidersBranches"
import {
  GitProvidersOrganizations,
  GitProvidersOrganizationsRemoteModel,
} from "../../model/workspaces/integrations/GitProvidersOrganizations"
import {
  GitProvidersProjects,
  GitProvidersProjectsRemoteModel,
} from "../../model/workspaces/integrations/GitProvidersProjects"
import {
  GitProvidersRepositories,
  GitProvidersRepositoriesRemoteModel,
} from "../../model/workspaces/integrations/GitProvidersRepositories"
import { sortUsingMap, sureOf } from "../../utils/CommonUtils"
import {
  ComponentUpdateTransactionData,
  TokenUpdateTransactionData,
} from "../areas/SDKAreaBulkOperations"
import { AssetGroupResolver } from "../resolvers/SDKAssetGroupResolver"
import { ComponentGroupResolver } from "../resolvers/SDKComponentGroupResolver"
import { ComponentResolver } from "../resolvers/SDKComponentResolver"
import { DesignComponentGroupResolver } from "../resolvers/SDKDesignComponentGroupResolver"
import { TokenGroupResolver } from "../resolvers/SDKTokenGroupResolver"
import { TokenResolver } from "../resolvers/SDKTokenResolver"

import { DataCoreReadRequests } from "./requests/SDKDataCoreReadRequests"
import {
  DataCoreRenderRequests,
  RenderedAssetRequestSettings,
} from "./requests/SDKDataCoreRenderRequests"
import { DataCoreWriteRequests } from "./requests/SDKDataCoreWriteRequests"

import { DataBridge } from "./SDKDataBridge"

import f, { RequestInfo, RequestInit, Response } from "node-fetch"
import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Function Definition

export class DataCore {
  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Configuration

  /** Read part of the network calls */
  private read: DataCoreReadRequests

  /** Write part of the network calls */
  private write: DataCoreWriteRequests

  /** Asset generation part of the network calls */
  private render: DataCoreRenderRequests

  /** Bridge */
  private bridge: DataBridge

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(bridge: DataBridge) {
    this.bridge = bridge
    this.read = new DataCoreReadRequests(bridge)
    this.write = new DataCoreWriteRequests(bridge)
    this.render = new DataCoreRenderRequests(bridge)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Aux

  /** Only for internal functionality */
  getBridgeInternal(): DataBridge {
    return this.bridge
  }

  async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
    return f(url, init)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - CORS

  async dataThroughCorsProxy(
    urlToRalay: string
  ): Promise<ArrayBuffer | undefined> {
    return this.read.getDataThroughCorsProxy(urlToRalay)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Bootstrap data

  async bootstrapData(
    preferredWorkspaceId?: string,
    preferredDesignSystemId?: string,
    preferredVersionId?: string,
    preferredBrandId?: string
  ): Promise<BootstrapData> {
    const data = await this.read.getRemoteBootstrapData(
      preferredWorkspaceId,
      preferredDesignSystemId,
      preferredVersionId,
      preferredBrandId
    )

    return new BootstrapData(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Multiplayer

  /** Remote: Get single-use multiplayer token */
  async multiplayerToken(room: string | undefined): Promise<{
    token: string
  }> {
    const data = await this.read.getRemoteMultiplayerToken(room)
    return data
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Users

  /** Remote: Get information about currently logged in user */
  async currentUser(): Promise<User> {
    const data = await this.read.getRemoteCurrentUser()

    return new User(data)
  }

  /** Remote: Get information about a specific user */
  async user(userId: string): Promise<User> {
    const data = await this.read.getRemoteUser(userId)

    return new User(data)
  }

  /** Remote: Get information about all workspaces user has access to, including which role they play */
  async memberships(userId: string): Promise<Array<Membership>> {
    const data = await this.read.getRemoteMemberships(userId)

    return data.map((m) => new Membership(m))
  }

  /** Remote: Get information about all workspaces user has access to, including which role they play */
  async userMemberships(workspaceId: string): Promise<Array<UserMembership>> {
    const data = await this.read.getRemoteUserMemberships(workspaceId)

    return data.map((m) => new UserMembership(m))
  }

  /** Remote: Get information about user workspace notification settings */
  async userWorkspaceNotificationSettings(
    userId: string,
    workspaceId: string
  ): Promise<UserWorkspaceNotificationSettings> {
    const data = await this.read.getRemoteUserWorkspaceNotificationSettings(
      userId,
      workspaceId
    )

    return new UserWorkspaceNotificationSettings(data)
  }

  async acls(): Promise<Acl> {
    const data = await this.read.getRemoteAcls()

    return new Acl(data)
  }

  /** Deletes the user by id. Can only really delete its own user */
  async deleteUser(userId: string): Promise<void> {
    return this.write.deleteRemoteUser(userId)
  }

  /** Checks whether user can be deleted or not. Can only really check its own user */
  async canDeleteUser(userId: string): Promise<boolean> {
    return this.write.canDeleteRemoteUser(userId)
  }

  /** Logs out user. Can only really logout its own user */
  async logoutCurrentUser(): Promise<void> {
    return this.write.logoutRemoteCurrentUser()
  }

  /** Updates the user profile of specified user. Can only really update its own user */
  async updateUserProfile(
    userId: string,
    profile: UserProfileUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteUserProfile(profile, userId)
  }

  /** Updates the avatar of the current user */
  async updateUserAvatar(userId: string, avatar: File): Promise<void> {
    return this.write.updateRemoteUserAvatar(userId, avatar)
  }

  /** Updates the avatar of the current user */
  async deleteUserAvatar(userId: string): Promise<void> {
    return this.write.deleteRemoteUserAvatar(userId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Elements: Base

  /** Download all necessary data for element property definitions and retrieve them resolved */
  async elementPropertyDefinitions(
    designSystemId: string,
    versionId: string,
    type: ElementPropertyTargetElementType
  ): Promise<Array<ElementProperty>> {
    // Batch requests together
    const data = await Promise.all([
      this.read.getRemoteElementPropertyDefinitions(designSystemId, versionId),
      this.elementViews(designSystemId, versionId, type),
    ])

    const elementPropertyData = data[0]
    const elementViews = data[1]
    let resolvedProperties = elementPropertyData.map(
      (p) => new ElementProperty(p)
    )

    // Sort properties using views so they come in correct order to client
    const firstView = sureOf(elementViews.filter((v) => v.isDefault)[0])
    const indexes = new Map<string, number>()

    for (const column of firstView.columns) {
      if (column.propertyDefinitionId) {
        indexes.set(
          column.propertyDefinitionId,
          firstView.columns.indexOf(column)
        )
      }
    }

    resolvedProperties = sortUsingMap(
      resolvedProperties,
      indexes,
      (p) => p.id
    ).filter((p) => p.targetElementType === type)

    return resolvedProperties
  }

  /** Download all necessary data for element property values and retrieve them resolved */
  async elementPropertyValues(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ElementPropertyValue>> {
    const data = await this.read.getRemoteElementPropertyValues(
      designSystemId,
      versionId
    )

    const resolvedValues = data.map((v) => new ElementPropertyValue(v))

    return resolvedValues
  }

  /** Download all necessary data for element views and retrieve them resolved */
  async elementViews(
    designSystemId: string,
    versionId: string,
    type: ElementPropertyTargetElementType
  ): Promise<Array<ElementDataView>> {
    const data = await this.read.getRemoteElementDataViewDefinitions(
      designSystemId,
      versionId
    )

    const resolvedViews = data.map((v) => new ElementDataView(v))

    return resolvedViews.filter((v) => v.targetElementType === type)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Token collections

  async tokenCollections(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DesignSystemCollection>> {
    const data = await this.read.getRemoteTokenCollections(
      designSystemId,
      versionId
    )
    return data.map((m) => new DesignSystemCollection(m))
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Elements: Tokens

  /** Download all necessary data for tokens and retrieve them resolved */
  async tokens(
    designSystemId: string,
    versionId: string,
    filter?: {
      type?: TokenType
      brandId?: string
    }
  ): Promise<Array<Token>> {
    // Batch requests together
    const data = await Promise.all([
      this.read.getRemoteTokens(designSystemId, versionId, filter),
      this.tokenGroups(designSystemId, versionId),
      this.elementPropertyDefinitions(
        designSystemId,
        versionId,
        ElementPropertyTargetElementType.token
      ),
      this.elementPropertyValues(designSystemId, versionId),
      this.elementViews(
        designSystemId,
        versionId,
        ElementPropertyTargetElementType.token
      ),
      this.designSystemSources(designSystemId),
    ])

    const tokenData = data[0]
    const tokenGroups = data[1]
    const elementPropertyDefinitions = data[2]
    const elementPropertyValues = data[3]
    const elementViews = data[4]
    const sources = data[5]
    const resolver = new TokenResolver(designSystemId, versionId)

    const tokens = resolver.resolveTokenData(
      tokenData,
      tokenGroups,
      elementPropertyDefinitions,
      elementViews,
      elementPropertyValues,
      sources
    )

    return tokens
  }

  /** Download all necesssary data for token usage and retrieve them resolved */
  async getTokenUsage(
    designSystemId: string,
    versionId: string,
    idInVersion: string
  ): Promise<{
    tokens: Array<string>
    components: Array<string>
    documentationPages: Array<string>
  }> {
    const usageData = await this.read.getRemoteTokenUsage(
      designSystemId,
      versionId,
      idInVersion
    )

    const mappedData = {
      tokens: usageData.tokens.map((t) => t.persistentId),
      components: usageData.components.map((c) => c.persistentId),
      documentationPages: usageData.documentationPages.map(
        (d) => d.persistentId
      ),
    }

    return mappedData
  }

  /** Write new token to remote */
  async createToken(
    designSystemId: string,
    versionId: string,
    token: Token,
    parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const tokenWriteObject = token.toWriteObject()

    tokenWriteObject.groupPersistentId = parentId

    const remoteToken = await this.write.createRemoteToken(
      designSystemId,
      versionId,
      tokenWriteObject
    )

    return {
      id: remoteToken.persistentId,
      idInVersion: remoteToken.id,
    }
  }

  /** Update existing token in remote */
  async updateToken(
    designSystemId: string,
    versionId: string,
    token: Token
  ): Promise<void> {
    const tokenWriteObject = token.toWriteObject()

    await this.write.updateRemoteToken(
      designSystemId,
      versionId,
      tokenWriteObject
    )
  }

  /** Write new token group to remote */
  async createTokenGroup(
    designSystemId: string,
    versionId: string,
    group: TokenGroup,
    parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const groupWriteObject = group.toWriteObject()

    groupWriteObject.parentId = parentId

    const remoteGroup = await this.write.createRemoteTokenGroup(
      designSystemId,
      versionId,
      groupWriteObject
    )

    return {
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      id: remoteGroup.persistentId,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      idInVersion: remoteGroup.id,
    }
  }

  /** Update existing token in remote */
  async updateTokenGroup(
    designSystemId: string,
    versionId: string,
    group: TokenGroup
  ): Promise<void> {
    const tokenGroupWriteObject = group.toWriteObject()

    await this.write.updateRemoteTokenGroup(
      designSystemId,
      versionId,
      tokenGroupWriteObject
    )
  }

  /** Download all necessary data for token groups and retrieve them resolved */
  async tokenGroups(
    designSystemId: string,
    versionId: string,
    filter?: {
      type?: TokenType
      brandId?: string
    }
  ): Promise<Array<TokenGroup>> {
    const tokenGroupData = await this.read.getRemoteTokenGroups(
      designSystemId,
      versionId,
      filter
    )

    const resolver = new TokenGroupResolver()
    return resolver.resolveGroupData(tokenGroupData)
  }

  /** Write new token theme to remote */
  async createTokenTheme(
    designSystemId: string,
    versionId: string,
    theme: TokenTheme
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const themeWriteObject = theme.toWriteObject()

    const remoteTheme = await this.write.createRemoteTokenTheme(
      designSystemId,
      versionId,
      themeWriteObject
    )

    return {
      id: remoteTheme.persistentId,
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      idInVersion: remoteTheme.id,
    }
  }

  /** Download all necessary data for token themes and retrieve them resolved */
  async themes(
    designSystemId: string,
    versionId: string
  ): Promise<Array<TokenTheme>> {
    // Batch requests together
    const data = await Promise.all([
      this.read.getRemoteTokenThemes(designSystemId, versionId),
      this.tokens(designSystemId, versionId),
      this.tokenGroups(designSystemId, versionId),
      this.designSystemSources(designSystemId),
    ])

    const themeData = data[0]
    const tokens = data[1]
    const tokenGroups = data[2]
    const sources = data[3]
    const resolvedThemes = new Array<TokenTheme>()

    for (const themeModel of themeData) {
      const resolver = new TokenResolver(designSystemId, versionId)
      const result = resolver.resolveThemeData(
        themeModel,
        tokens,
        tokenGroups,
        sources
      )

      resolvedThemes.push(result)
    }

    return resolvedThemes
  }

  /** Update existing token theme in remote */
  async updateTokenTheme(
    designSystemId: string,
    versionId: string,
    theme: TokenTheme
  ): Promise<void> {
    const tokenThemeWriteObject = theme.toWriteObject()

    await this.write.updateRemoteTokenTheme(
      designSystemId,
      versionId,
      tokenThemeWriteObject
    )
  }

  async createThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    themeId: string,
    override: TokenThemeOverrideTranserObject
  ): Promise<void> {
    return this.write.createThemeOverrideForToken(
      designSystemId,
      versionId,
      themeId,
      override
    )
  }

  /** Update theme override for token */
  async updateThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    tokenPersistentId: string,
    themeId: string,
    override: TokenThemeOverrideTranserObject
  ): Promise<void> {
    return this.write.updateThemeOverrideForToken(
      designSystemId,
      versionId,
      tokenPersistentId,
      themeId,
      override
    )
  }

  /** Delete theme override for token */
  async deleteThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    tokenId: string,
    themeId: string
  ): Promise<void> {
    return this.write.deleteThemeOverrideForToken(
      designSystemId,
      versionId,
      tokenId,
      themeId
    )
  }

  /** Write the tokens to remote */
  async writeTokenData(
    designSystemId: string,
    versionId: string,
    tokens: Array<Token>,
    groups: Array<TokenGroup>,
    deleteTokens: Array<Token>,
    deleteGroups: Array<TokenGroup>
  ): Promise<void> {
    return this.write.writeTokenData(
      designSystemId,
      versionId,
      tokens.map((t) => t.toWriteObject()),
      groups.map((g) => g.toWriteObject()),
      deleteTokens,
      deleteGroups
    )
  }

  async writeTokenThemeData(
    designSystemId: string,
    versionId: string,
    theme: TokenTheme
  ): Promise<void> {
    const themeModel = theme.toWriteObject()

    return this.write.writeTokenThemeData(designSystemId, versionId, themeModel)
  }

  /** Delete token from remote */
  async deleteToken(
    designSystemId: string,
    versionId: string,
    tokenId: string
  ): Promise<void> {
    return this.write.deleteRemoteToken(designSystemId, versionId, tokenId)
  }

  /** Delete group from remote */
  async ungroupTokenGroup(
    designSystemId: string,
    versionId: string,
    groupId: string
  ): Promise<void> {
    return this.write.ungroupRemoteTokenGroup(
      designSystemId,
      versionId,
      groupId
    )
  }

  /** Delete group from remote */
  async deleteTokenGroup(
    designSystemId: string,
    versionId: string,
    tokenGroupToDelete: TokenGroup,
    tokens: Array<Token>,
    tokenGroups: Array<TokenGroup>
  ): Promise<void> {
    const toDelete = this.itemsToDeleteFromGroup(
      tokenGroupToDelete,
      tokens,
      tokenGroups
    )

    return this.writeTokenData(
      designSystemId,
      versionId,
      [],
      [],
      toDelete.itemsToDelete,
      toDelete.groupsToDelete
    )
  }

  private flattenedChildren<
    I extends Component | Token,
    G extends ComponentGroup | TokenGroup
  >(
    group: G,
    allItems: Array<I>,
    allItemGroups: Array<G>
  ): {
    items: Array<I>
    groups: Array<G>
  } {
    const resultItems: Array<I> = []
    const resultGroups: Array<G> = []

    // Find all immediate children
    const childItems = allItems.filter((t) => group.childrenIds.includes(t.id))
    const childGroups = allItemGroups.filter((g) =>
      group.childrenIds.includes(g.id)
    )

    resultItems.push(...childItems)
    resultGroups.push(...childGroups)

    // Find all nested children
    for (const childGroup of childGroups) {
      const result = this.flattenedChildren(childGroup, allItems, allItemGroups)

      resultItems.push(...result.items)
      resultGroups.push(...result.groups)
    }

    return {
      items: resultItems,
      groups: resultGroups,
    }
  }

  private itemsToDeleteFromGroup<
    I extends Component | Token,
    G extends ComponentGroup | TokenGroup
  >(
    group: G,
    allItems: Array<I>,
    allItemGroups: Array<G>
  ): {
    itemsToDelete: Array<I>
    groupsToDelete: Array<G>
  } {
    const children = this.flattenedChildren(group, allItems, allItemGroups)

    return {
      itemsToDelete: [...children.items],
      groupsToDelete: [group, ...children.groups],
    }
  }

  /** Update token property at remote */
  async updateTokenPropertyValue(
    designSystemId: string,
    versionId: string,
    newValue: string | boolean | number,
    token: Token,
    property: ElementProperty
  ) {
    return this.write.updateTokenPropertyValue(
      designSystemId,
      versionId,
      newValue,
      token.id,
      property.id
    )
  }

  /** Delete token property in remote */
  async deleteTokenPropertyValue(
    designSystemId: string,
    versionId: string,
    valueId: string
  ) {
    return this.write.deleteTokenPropertyValue(
      designSystemId,
      versionId,
      valueId
    )
  }

  /** Create a custom property in remote */
  async createTokenProperty(
    designSystemId: string,
    versionId: string,
    model: ElementPropertyCreationModel
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const payload = {
      meta: {
        name: model.name,
        description: model.description,
      },
      type: model.type,
      targetElementType: ElementPropertyTargetElementType.token,
      codeName: model.codeName,
      columnWidth: model.columnWidth,
      linkElementType: model.linkElementType,
      options: model.options,
      persistentId: uuidv4(),
    }

    const remoteProperty = await this.write.createTokenProperty(
      designSystemId,
      versionId,
      payload
    )

    return {
      id: remoteProperty.persistentId,
      idInVersion: remoteProperty.id,
    }
  }

  /** Create a page within documentation */
  async createDocumentationPage(
    designSystemId: string,
    versionId: string,
    payload: Omit<DTOCreateDocumentationPageInputV2, "persistentId">
  ): Promise<string> {
    const persistentId = uuidv4()
    await this.write.createRemoteDocumentationPage(designSystemId, versionId, {
      ...payload,
      persistentId,
    })
    return persistentId
  }

  /** General element action */
  async elementAction(
    designSystemId: string,
    versionId: string,
    data: DTOElementActionInput
  ) {
    return this.write.elementAction(designSystemId, versionId, data)
  }

  /** Update page approval state */
  async updateApprovalState(
    designSystemId: string,
    versionId: string,
    payload: DTODocumentationPageApprovalStateChangeInput
  ): Promise<string> {
    return this.write.updateApprovalState(designSystemId, versionId, payload)
  }

  /** Update design system's access mode and, if switching to invite-only, also the member list */
  async updateDesignSystemAccessModeAndMemberList(
    designSystemId: string,
    accessMode: DesignSystemAccessMode,
    retainUserIds: Array<string>,
    retainInviteIds: Array<string>
  ): Promise<void> {
    return this.write.updateRemoteDesignSystemAccessModeAndMemberList(
      designSystemId,
      accessMode,
      retainUserIds,
      retainInviteIds
    )
  }

  /** Update design system's member list */
  async updateDesignSystemMemberList(
    designSystemId: string,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>,
    invitesToUpdate?: Array<DesignSystemInviteUpdate>,
    deleteInvitationIds?: Array<string>,
    usersToUpdate?: Array<DesignSystemMemberUpdate>,
    removeUserIds?: Array<string>
  ): Promise<void> {
    return this.write.updateRemoteDesignSystemMemberList(
      designSystemId,
      usersToInvite,
      invitesToInvite,
      emailsToInvite,
      invitesToUpdate,
      deleteInvitationIds,
      usersToUpdate,
      removeUserIds
    )
  }

  /** Create a group within documentation */
  async createDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: Omit<DTOCreateDocumentationGroupInput, "persistentId">
  ) {
    const persistentId = uuidv4()
    await this.write.createRemoteDocumentationGroup(designSystemId, versionId, {
      ...payload,
      persistentId,
    })
    return persistentId
  }

  /** Create a tab group within documentation */
  async createDocumentationTab(
    designSystemId: string,
    versionId: string,
    payload: Omit<DTOCreateDocumentationTabInput, "persistentId">
  ) {
    const persistentId = uuidv4()
    await this.write.createRemoteDocumentationTab(designSystemId, versionId, {
      ...payload,
      persistentId,
    })
    return persistentId
  }

  /** Ungroup a documentaiton group */
  async ungroupDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: DTODeleteDocumentationGroupInput
  ) {
    await this.write.deleteRemoteDocumentationGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Updates a documentation group */
  async updateDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: DTOUpdateDocumentationGroupInput
  ) {
    return this.write.updateRemoteDocumentationGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Updates a documentation page or tab */
  async updateDocumentationPageOrTab(
    designSystemId: string,
    versionId: string,
    payload: DTOUpdateDocumentationPageInputV2
  ) {
    return this.write.updateRemoteDocumentationPage(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Move a documentation page */
  async moveDocumentationPage(
    designSystemId: string,
    versionId: string,
    payload: DTOMoveDocumentationPageInputV2
  ) {
    return this.write.moveRemoteDocumentationPage(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Move a documentation group */
  async moveDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: DTOMoveDocumentationGroupInput
  ) {
    return this.write.moveRemoteDocumentationGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Duplicate a documentation page or tab */
  async duplicateDocumentationPageOrTab(
    designSystemId: string,
    versionId: string,
    payload: Omit<DTODuplicateDocumentationPageInputV2, "persistentId">
  ) {
    const persistentId = uuidv4()
    await this.write.duplicateRemoteDocumentationPage(
      designSystemId,
      versionId,
      { ...payload, persistentId }
    )
    return persistentId
  }

  /** Duplicate a documentation group or tabbed page */
  async duplicateDocumentationGroupOrTabbedPage(
    designSystemId: string,
    versionId: string,
    payload: Omit<DTODuplicateDocumentationGroupInput, "persistentId">
  ) {
    const persistentId = uuidv4()
    await this.write.duplicateRemoteDocumentationGroup(
      designSystemId,
      versionId,
      { ...payload, persistentId }
    )
    return persistentId
  }

  /** Delete a documentation page */
  async deleteDocumentationPage(
    designSystemId: string,
    versionId: string,
    payload: DTODeleteDocumentationPageInputV2
  ) {
    await this.write.deleteRemoteDocumentationPage(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Delete a documentation tab group */
  async deleteDocumentationTabGroup(
    designSystemId: string,
    versionId: string,
    payload: DTODeleteDocumentationTabGroupInput
  ) {
    await this.write.deleteRemoteDocumentationTabGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Delete a documentation group */
  async deleteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: DTODeleteDocumentationGroupInput
  ): Promise<void> {
    await this.write.deleteRemoteDocumentationGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Delete a documentation group and tree inside it */
  async deleteDocumentationGroupAndTree(
    designSystemId: string,
    versionId: string,
    id: string
  ): Promise<void> {
    await this.write.deleteRemoteDocumentationGroup(designSystemId, versionId, {
      id,
      deleteSubtree: true,
    })
  }

  /** Restore a documentation group */
  async restoreDocumentationGroup(
    designSystemId: string,
    versionId: string,
    payload: DTORestoreDocumentationGroupInput
  ) {
    await this.write.restoreRemoteDocumentationGroup(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Restore a documentation page   */
  async restoreDocumentationPage(
    designSystemId: string,
    versionId: string,
    payload: DTORestoreDocumentationPageInput
  ) {
    await this.write.restoreRemoteDocumentationPage(
      designSystemId,
      versionId,
      payload
    )
  }

  /** Create a custom property in remote */
  async updateTokenProperty(
    designSystemId: string,
    versionId: string,
    propertyIdInVersion: string,
    model: ElementPropertyUpdateModel
  ): Promise<void> {
    const payload = {
      meta: {
        name: model.name,
        description: model.description,
      },
      codeName: model.codeName,
      options: model.options,
    }

    return this.write.updateTokenProperty(
      designSystemId,
      versionId,
      propertyIdInVersion,
      payload
    )
  }

  /** Delete a custom property in remote */
  async deleteTokenProperty(
    designSystemId: string,
    versionId: string,
    propertyId: string
  ) {
    return this.write.deleteTokenProperty(designSystemId, versionId, propertyId)
  }

  /** Move column to a new index and reorder all other columns around it */
  async updateReorderTokenColumn(
    designSystemId: string,
    versionId: string,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newIndex: number
  ): Promise<void> {
    return this.write.updateReorderTokenColumn(
      designSystemId,
      versionId,
      view.id,
      column.id,
      newIndex
    )
  }

  /** Update column size */
  async updateResizeTokenColumn(
    designSystemId: string,
    versionId: string,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newWidth: number
  ): Promise<void> {
    return this.write.updateResizeTokenColumn(
      designSystemId,
      versionId,
      view.id,
      column.type,
      column.id,
      column.themeId ?? undefined,
      column.basePropertyType ?? undefined,
      column.propertyDefinitionId ?? undefined,
      newWidth
    )
  }

  /** Delete theme from remote */
  async deleteTokenTheme(
    designSystemId: string,
    versionId: string,
    themeId: string
  ): Promise<void> {
    return this.write.deleteRemoteTokenTheme(designSystemId, versionId, themeId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Elements: Components

  /** Download all necessary data for components and retrieve them resolved  */
  async components(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<Component>> {
    // Batch requests together
    const data = await Promise.all([
      this.read.getRemoteComponents(designSystemId, versionId, filter),
      this.elementPropertyDefinitions(
        designSystemId,
        versionId,
        ElementPropertyTargetElementType.component
      ),
      this.elementPropertyValues(designSystemId, versionId),
      this.elementViews(
        designSystemId,
        versionId,
        ElementPropertyTargetElementType.component
      ),
    ])

    const componentData = data[0]
    const elementPropertyDefinitions = data[1]
    const elementPropertyValues = data[2]
    const elementViews = data[3]
    const resolver = new ComponentResolver()

    const components = resolver.resolveComponentData(
      componentData,
      elementPropertyDefinitions,
      elementPropertyValues,
      elementViews
    )

    return components
  }

  /** Download all necessary data for component groups and retrieve them resolved */
  async componentGroups(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ComponentGroup>> {
    const data = await Promise.all([
      this.read.getRemoteComponentGroups(designSystemId, versionId),
      this.components(designSystemId, versionId),
    ])

    const componentGroupData = data[0]
    const components = data[1]
    const resolver = new ComponentGroupResolver(components)
    const groups = resolver.resolveGroupData(componentGroupData)

    return groups
  }

  async designComponents(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponent>> {
    // Batch requests
    const data = await Promise.all([
      this.read.getRemoteDesignComponents(designSystemId, versionId, filter),
      this.designSystemSources(designSystemId),
    ])

    const [designComponentData, sources] = data

    const components: DesignComponent[] = []
    // Map for better performance
    const componentVariantsMap = new Map<string, DesignComponentVariant>()

    designComponentData.forEach((component) => {
      if (!component.parentComponentPersistentId) {
        components.push(new DesignComponent(component, sources))
        return
      }
      componentVariantsMap.set(
        component.persistentId,
        new DesignComponentVariant(component, sources)
      )
    })

    components.forEach((component) => {
      if (component.childrenPersistentIds) {
        // fill children by component variants if exists
        component.children = component.childrenPersistentIds.reduce(
          (children: Array<DesignComponentVariant>, id) => {
            const variant = componentVariantsMap.get(id)
            if (variant) {
              children.push(variant)
            }
            return children
          },
          []
        )
      }
    })

    return components
  }

  async designComponentGroups(
    designSystemId: string,
    versionId: string,
    filter?: {
      brandId?: string
    }
  ): Promise<Array<DesignComponentGroup>> {
    const data = await Promise.all([
      this.read.getRemoteDesignComponentGroups(
        designSystemId,
        versionId,
        filter
      ),
      this.designComponents(designSystemId, versionId, filter),
    ])

    const designComponentGroupData = data[0]
    const designComponents = data[1]

    const resolver = new DesignComponentGroupResolver(designComponents)

    return resolver.resolveGroupData(designComponentGroupData)
  }

  /** Write new component to remote */
  async createComponent(
    designSystemId: string,
    versionId: string,
    component: Component
    // parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const componentWriteObject = component.toWriteObject()

    // componentWriteObject.groupPersistentId = parentId <- Enable this once we support groups

    const remoteComponent = await this.write.createRemoteComponent(
      designSystemId,
      versionId,
      componentWriteObject
    )

    return {
      id: remoteComponent.persistentId,
      idInVersion: remoteComponent.id,
    }
  }

  /** Update existing component in remote */
  async updateComponent(
    designSystemId: string,
    versionId: string,
    component: Component
  ): Promise<void> {
    const componentWriteObject = component.toWriteUpdateObject()

    await this.write.updateRemoteComponent(
      designSystemId,
      versionId,
      componentWriteObject
    )
  }

  /** Write new component group to remote */
  async createComponentGroup(
    designSystemId: string,
    versionId: string,
    group: ComponentGroup
    // parentId: string
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const groupWriteObject = group.toWriteObject()

    // groupWriteObject.parentId = parentId

    const remoteGroup = await this.write.createRemoteComponentGroup(
      designSystemId,
      versionId,
      groupWriteObject
    )

    return {
      id: remoteGroup.persistentId,
      idInVersion: remoteGroup.id,
    }
  }

  /** Update existing component group in remote */
  async updateComponentGroup(
    designSystemId: string,
    versionId: string,
    group: ComponentGroup
  ): Promise<void> {
    const componentGroupWriteObject = group.toWriteObject()

    await this.write.updateRemoteComponentGroup(
      designSystemId,
      versionId,
      componentGroupWriteObject
    )
  }

  /** Update component property at remote */
  async updateComponentPropertyValue(
    designSystemId: string,
    versionId: string,
    newValue: string | boolean | number,
    component: Component,
    property: ElementProperty
  ) {
    return this.write.updateComponentPropertyValue(
      designSystemId,
      versionId,
      newValue,
      component.id,
      property.id
    )
  }

  /** Delete component property in remote */
  async deleteComponentPropertyValue(
    designSystemId: string,
    versionId: string,
    valueId: string
  ) {
    return this.write.deleteComponentPropertyValue(
      designSystemId,
      versionId,
      valueId
    )
  }

  /** Create a custom property in remote */
  async createComponentProperty(
    designSystemId: string,
    versionId: string,
    model: ElementPropertyCreationModel
  ): Promise<{
    id: string
    idInVersion: string
  }> {
    const payload = {
      meta: {
        name: model.name,
        description: model.description,
      },
      type: model.type,
      targetElementType: ElementPropertyTargetElementType.component,
      codeName: model.codeName,
      columnWidth: model.columnWidth,
      linkElementType: model.linkElementType,
      options: model.options,
      persistentId: uuidv4(),
    }

    const remoteProperty = await this.write.createComponentProperty(
      designSystemId,
      versionId,
      payload
    )

    return {
      id: remoteProperty.persistentId,
      idInVersion: remoteProperty.id,
    }
  }

  /** Create a custom property in remote */
  async updateComponentProperty(
    designSystemId: string,
    versionId: string,
    propertyIdInVersion: string,
    model: ElementPropertyUpdateModel
  ): Promise<void> {
    const payload = {
      meta: {
        name: model.name,
        description: model.description,
      },
      codeName: model.codeName,
      options: model.options,
    }

    return this.write.updateComponentProperty(
      designSystemId,
      versionId,
      propertyIdInVersion,
      payload
    )
  }

  /** Delete a custom property in remote */
  async deleteComponentProperty(
    designSystemId: string,
    versionId: string,
    propertyId: string
  ) {
    return this.write.deleteComponentProperty(
      designSystemId,
      versionId,
      propertyId
    )
  }

  /** Move column to a new index and reorder all other columns around it */
  async updateReorderComponentColumn(
    designSystemId: string,
    versionId: string,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newIndex: number
  ): Promise<void> {
    return this.write.updateReorderComponentColumn(
      designSystemId,
      versionId,
      view.id,
      column.id,
      newIndex
    )
  }

  /** Update column size */
  async updateResizeComponentColumn(
    designSystemId: string,
    versionId: string,
    view: ElementDataView,
    column: ElementDataViewColumn,
    newWidth: number
  ): Promise<void> {
    return this.write.updateResizeComponentColumn(
      designSystemId,
      versionId,
      view.id,
      column.type,
      column.id,
      column.themeId ?? undefined,
      column.basePropertyType ?? undefined,
      column.propertyDefinitionId ?? undefined,
      newWidth
    )
  }

  /** Delete component from remote */
  async deleteComponent(
    designSystemId: string,
    versionId: string,
    componentId: string
  ): Promise<void> {
    return this.write.deleteRemoteComponent(
      designSystemId,
      versionId,
      componentId
    )
  }

  /** Delete group from remote */
  async ungroupComponentGroup(
    designSystemId: string,
    versionId: string,
    groupId: string
  ): Promise<void> {
    return this.write.ungroupRemoteComponentGroup(
      designSystemId,
      versionId,
      groupId
    )
  }

  /** Delete group from remote */
  async deleteComponentGroup(
    designSystemId: string,
    versionId: string,
    componentGroupToDelete: ComponentGroup,
    components: Array<Component>,
    componentGroups: Array<ComponentGroup>
  ): Promise<void> {
    const toDelete = this.itemsToDeleteFromGroup(
      componentGroupToDelete,
      components,
      componentGroups
    )

    return this.writeComponentData(
      designSystemId,
      versionId,
      [],
      [],
      toDelete.itemsToDelete,
      toDelete.groupsToDelete
    )
  }

  /** Write the component to remote */
  async writeComponentData(
    designSystemId: string,
    versionId: string,
    components: Array<Component>,
    groups: Array<ComponentGroup>,
    deleteComponents: Array<Component>,
    deleteGroups: Array<ComponentGroup>
  ): Promise<void> {
    return this.write.writeComponentData(
      designSystemId,
      versionId,
      components.map((t) => t.toWriteObject()),
      groups.map((g) => g.toWriteObject()),
      deleteComponents,
      deleteGroups
    )
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Elements: Assets

  /** Download all necessary data for assets and retrieve them resolved */
  async assets(
    designSystemId: string,
    versionId: string
  ): Promise<Array<Asset>> {
    // Batch requests
    const data = await Promise.all([
      this.read.getRemoteAssets(designSystemId, versionId),
      this.designSystemSources(designSystemId),
    ])

    const assetData = data[0]
    const sources = data[1]
    // Manage duplicates
    const assetNames = new Map<string, number>()

    for (const item of assetData) {
      if (item.exportProperties.isAsset) {
        assetNames.set(item.meta.name.toLowerCase(), 0)
      }
    }

    // Transform only exportable designComponents into assets
    const assets: Array<Asset> = []

    for (const asset of assetData) {
      const lowercasedName = asset.meta.name.toLowerCase()

      if (asset.exportProperties.isAsset) {
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        assets.push(new Asset(asset, assetNames.get(lowercasedName), sources))
        // Increase number of duplicates
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        // TODO:fix-sdk-eslint
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        assetNames.set(lowercasedName, assetNames.get(lowercasedName) + 1)
      }
    }

    return assets
  }

  /** Download all necessary data for asset groups and retrieve them resolved */
  async assetGroups(
    designSystemId: string,
    versionId: string
  ): Promise<Array<AssetGroup>> {
    const data = await Promise.all([
      this.read.getRemoteAssetGroups(designSystemId, versionId),
      this.assets(designSystemId, versionId),
    ])

    const assetGroupData = data[0]
    const assets = data[1]
    const resolver = new AssetGroupResolver(assets)
    const result = resolver.resolveGroupData(assetGroupData)

    return result
  }

  /** Render assets as PNG, SVG, PDF, or combinations of it */
  async renderedAssets(
    designSystemId: string,
    versionId: string,
    assets: Array<Asset>,
    groups: Array<AssetGroup>,
    settings: RenderedAssetRequestSettings
  ): Promise<Array<RenderedAsset>> {
    return this.render.renderAssetsForConfiguration(
      designSystemId,
      versionId,
      settings,
      assets,
      groups
    )
  }

  /** Render Figma frames */
  async renderedFigmaFrames(
    designSystemId: string,
    versionId: string,
    payload: Array<DTOFigmaNodeRenderIdInput>
  ): Promise<Array<DTOFigmaNode>> {
    const result = await this.write.renderFigmaFrames(
      designSystemId,
      versionId,
      payload
    )
    return result
  }

  /** Render Figma frames async */
  async renderedFigmaFramesAsync(
    designSystemId: string,
    versionId: string,
    payload: Array<DTOFigmaNodeRenderInput>
  ): Promise<Array<DTOFigmaNode>> {
    const result = await this.write.renderFigmaFramesAsync(
      designSystemId,
      versionId,
      payload
    )
    return result
  }

  /** Fetches all Figma frames that were rendered under specific version
   * @param from - Remote version to fetch from
   * @returns All Figma frames in the specified version (only rendered, for documentation etc.)
   */
  async figmaFrames(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DTOFigmaNode>> {
    return this.read.getRemoteFigmaFrames(designSystemId, versionId)
  }

  /** Fetches all Figma frames that were rendered under specific version (V2 of the API)
   * @param from - Remote version to fetch from
   * @returns All Figma frames in the specified version (only rendered, for documentation etc.)
   */
  async figmaFramesV2(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DTOFigmaNodeV2>> {
    return this.read.getRemoteFigmaFramesV2(designSystemId, versionId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - DocumentationLegacy

  /** Retrieve the main documentation object through which all docs data can be accessed */
  async documentation(
    workspaceId: string,
    designSystemId: string,
    versionId: string
  ): Promise<DocumentationLegacy> {
    // Batch requests together
    const data = await Promise.all([
      this.read.getRemoteDocumentationStructure(designSystemId, versionId),
      this.workspaceNPMRegistrySettings(workspaceId),
    ])

    const documentationData = data[0]
    const registry = data[1]
    const docs = new DocumentationLegacy(documentationData, registry)

    return docs
  }

  async getDocumentationStructure(
    designSystemId: string,
    versionId: string
  ): Promise<(DocsEntityPageModel | DocsEntityGroupModel)[]> {
    return this.read.getRemoteRealDocumentationStructure(
      designSystemId,
      versionId
    )
  }

  async getDocumentationElementsDiff(
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment
  ): Promise<DTODesignElementsDataDiffResponse> {
    return this.read.getRemoteDocumentationElementsDiff(
      designSystemId,
      versionId,
      environment
    )
  }

  async updateDocumentationStructure(
    designSystemId: string,
    versionId: string,
    model: DocumentationLegacyModel
  ): Promise<void> {
    await this.write.updateRemoteDocumentationStructure(
      designSystemId,
      versionId,
      model
    )
  }

  /** Get ID of the multiplayer room for a given documentation page */
  async getDocumentationPageRoomId(
    designSystemId: string,
    versionId: string,
    pageId: string
  ): Promise<string> {
    return this.read.getRemoteDocumentationPageRoomId(
      designSystemId,
      versionId,
      pageId
    )
  }

  /** Remote: Get id of the room for a specific design system and version. This room contains doc structure */
  async getDocumentationVersionRoomId(
    designSystemId: string,
    versionId: string
  ): Promise<string> {
    return this.read.getRemoteDocumentationVersionRoomId(
      designSystemId,
      versionId
    )
  }

  /** Remote: Get id of the room for a specific Workspace. This room contains comments */
  async getDocumentationWorkspaceRoomId(workspaceId: string): Promise<string> {
    return this.read.getRemoteDocumentationWorkspaceRoomId(workspaceId)
  }

  /** Retrieve currently deployed documentation url */
  async documentationDomain(
    designSystemId: string,
    versionId: string
  ): Promise<string | null> {
    const data = await this.read.getRemoteDocumentationUrl(
      designSystemId,
      versionId
    )

    return data
  }

  /** Publish the documentation */
  async documentationPublish(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment
  ): Promise<ExportBuild> {
    const data = await this.write.publishDocumentation(
      workspaceId,
      designSystemId,
      versionId,
      environment
    )

    return new ExportBuild(data)
  }

  /** Publish the documentation drafts */
  async documentationPublishDrafts(
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment,
    changes: DTOPublishDocumentationRequest["changes"]
  ): Promise<ExportBuild> {
    const data = await this.write.publishDocumentationDrafts(
      designSystemId,
      versionId,
      environment,
      changes
    )

    return new ExportBuild(data)
  }

  async getDocumentationPageAnchors(
    designSystemId: string,
    versionId: string,
    pageId: string
  ): Promise<Array<DTODocumentationPageAnchor>> {
    const data = await this.read.getRemoteDocumentationPageAnchors(
      designSystemId,
      versionId,
      pageId
    )

    return data.anchors
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resources

  /** Retrieve all asset resources */
  async assetResources(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ResourceAsset>> {
    const data = await this.read.getRemoteAssetResources(
      designSystemId,
      versionId
    )

    return data.map((d) => new ResourceAsset(d))
  }

  /** Retrieve all font resources */
  async fontResources(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ResourceFont>> {
    const data = await this.read.getRemoteFontResources(
      designSystemId,
      versionId
    )

    return data.map((d) => new ResourceFont(d))
  }

  /** Upload asset resource */
  async uploadAssetResource(
    designSystemId: string,
    versionId: string,
    asset: File
  ): Promise<ResourceAsset> {
    const data = await this.write.uploadAssetResource(
      designSystemId,
      versionId,
      asset
    )
    return new ResourceAsset(data)
  }

  /** Upload font resource */
  async uploadFontResource(
    designSystemId: string,
    versionId: string,
    font: File
  ): Promise<ResourceFont | null> {
    const data = await this.write.uploadFontResource(
      designSystemId,
      versionId,
      font
    )

    if (data) {
      return new ResourceFont(data)
    }

    return null
  }

  /** Upload font resources */
  async uploadFontResources(
    designSystemId: string,
    versionId: string,
    fonts: FileList
  ): Promise<ResourceFont[]> {
    const data = await this.write.uploadFontResources(
      designSystemId,
      versionId,
      fonts
    )

    return data.map((d) => new ResourceFont(d))
  }

  /** Delete any single resource */
  async deleteResource(
    designSystemId: string,
    versionId: string,
    resourceId: string
  ): Promise<void> {
    return this.write.deleteResource(designSystemId, versionId, resourceId)
  }

  /** Delete any multiple resources */
  async deleteResources(
    designSystemId: string,
    versionId: string,
    resourceIds: string[]
  ): Promise<void> {
    return this.write.deleteResources(designSystemId, versionId, resourceIds)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Pipelines

  async pipelines(
    workspaceId: string,
    designSystemId?: string
  ): Promise<Array<Pipeline>> {
    const data = await this.read.getRemotePipelines(workspaceId, designSystemId)

    return data.map((d) => new Pipeline(d))
  }

  async createPipeline(workspaceId: string, payload: DTOPipelineCreateBody) {
    const data = await this.write.createRemotePipeline(workspaceId, payload)
    return new Pipeline(data)
  }

  async updatePipeline(
    designSystemId: string,
    pipelineId: string,
    payload: DTOPipelineUpdateBody
  ) {
    const data = await this.write.updateRemotePipeline(
      designSystemId,
      pipelineId,
      payload
    )
    return new Pipeline(data)
  }

  async runPipeline(
    workspaceId: string,
    designSystemVersionId: string,
    pipelineId: string
  ): Promise<ExportBuild> {
    const data = await this.write.runRemotePipeline(
      workspaceId,
      designSystemVersionId,
      pipelineId
    )

    return new ExportBuild(data)
  }

  async deletePipeline(workspaceId: string, pipelineId: string): Promise<void> {
    await this.write.deleteRemotePipeline(workspaceId, pipelineId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Exporters

  /** Download information about current exporter custom blocks and retrieve them resolved */
  async documentationExporterCustomBlocks(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ExporterCustomBlock>> {
    const data = await this.read.getRemoteExporterCustomBlocks(
      designSystemId,
      versionId
    )

    const resolved = data.map((b) => new ExporterCustomBlock(b))

    return resolved
  }

  /** Download information about current exporter custom block variants and retrieve them resolved */
  async documentationExporterCustomVariants(
    workspaceId: string,
    exporterId: string
  ): Promise<Array<ExporterCustomBlockVariant>> {
    const data = await this.exporter(workspaceId, exporterId)

    return data.contributes.blockVariants
  }

  /** Download information about user settings (definitions) of the exporter and retrieve it resolved */
  async exporterConfigurationProperties(
    exporterId: string,
    workspaceId: string,
    designSystemId: string
  ): Promise<Array<ExporterConfigurationProperty>> {
    // Batch requests together
    const data = await Promise.all([
      this.exporterConfigurationPropertyValues(exporterId, designSystemId),
      this.exporter(workspaceId, exporterId),
    ])

    const propertyValues = data[0]
    const exporter = data[1]

    // Update properties with the downloaded data
    for (const property of exporter.contributes.configuration) {
      for (const settings of propertyValues) {
        if (property.key === settings.key) {
          // TODO:fix-sdk-eslint
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          property.updateValue(settings.value)
        }
      }
    }

    return exporter.contributes.configuration
  }

  /** Download information about user settings (values) of the exporter and retrieve it resolved */
  async exporterConfigurationPropertyValues(
    exporterId: string,
    designSystemId: string
  ): Promise<
    Array<{
      key: string
      value: any
    }>
  > {
    const data =
      await this.read.getRemoteExporterConfigurationUserSpecifiedValues(
        designSystemId,
        exporterId
      )

    return data // Note that no resolution is needed because data are served correctly from the server
  }

  /** Download information about installed exporter and retrieve it resolved */
  async exporter(workspaceId: string, exporterId: string): Promise<Exporter> {
    const data = await this.read.getRemoteExporter(workspaceId, exporterId)

    return new Exporter(data)
  }

  /** Download information about all installed exporter and retrieve it resolved */
  async exporters(
    workspaceId: string,
    filter?: {
      installedOnly?: boolean
    }
  ): Promise<Array<Exporter>> {
    const { exporters, membership } = await this.read.getRemoteExporters(
      workspaceId,
      filter
    )

    // Add isOwner property to each exporter based on the memberships
    return exporters.map((exporter) => {
      const { role } =
        membership.find((m) => m.exporterId === exporter.id) || {}
      return new Exporter({
        ...exporter,
        isOwner: role === "Owner",
      })
    })
  }

  /** Create new exporter and return Exporter as a result */
  async createExporter(workspaceId: string, payload: NewExporterPayload) {
    const data = await this.write.createRemoteExporter(workspaceId, payload)

    return new Exporter(data)
  }

  /** Edit exporter */
  async updateExporter(
    workspaceId: string,
    exporterId: string,
    payload: NewExporterPayload
  ): Promise<Exporter> {
    const data = await this.write.updateRemoteExporter(
      workspaceId,
      exporterId,
      payload
    )

    return new Exporter(data)
  }

  /** Update exporter configuration property value */
  async updateExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string,
    propertyValue: any
  ): Promise<void> {
    await this.write.updateRemoteExporterConfigurationPropertyValue(
      designSystemId,
      exporterId,
      propertyKey,
      propertyValue
    )
  }

  /** Delete exporter configuration property value */
  async deleteExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string
  ): Promise<void> {
    await this.write.deleteRemoteExporterConfigurationPropertyValue(
      designSystemId,
      exporterId,
      propertyKey
    )
  }

  /** Delete exporter */
  async deleteExporter(workspaceId: string, exporterId: string): Promise<void> {
    await this.write.deleteRemoteExporter(workspaceId, exporterId)
  }

  /** Pull updates for exporter */
  async pullLatestExporter(
    workspaceId: string,
    exporterId: string
  ): Promise<Exporter> {
    // Reason for empty payload is;
    // If it's not passed - BE uses stored git url and pulls changes from exporter
    const data = await this.write.updateRemoteExporter(
      workspaceId,
      exporterId,
      {}
    )
    return new Exporter(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Builds

  async exportBuilds(
    workspaceId: string,
    versionId: string
  ): Promise<Array<ExportBuild>> {
    const data = await this.read.getRemoteBuilds(workspaceId, versionId)

    return data.map((d) => new ExportBuild(d))
  }

  async exportBuild(
    workspaceId: string,
    buildId: string
  ): Promise<ExportBuild> {
    const data = await this.read.getRemoteBuild(workspaceId, buildId)

    return new ExportBuild(data)
  }

  /** Download information about documentation builds and retrieve it resolved */
  async documentationBuilds(
    workspaceId: string,
    versionId: string,
    environment: DocumentationEnvironment,
    status?: ExportBuildStatus,
    createdByUserId?: string,
    limit = 1
  ): Promise<Array<ExportBuild>> {
    const data = await this.read.getRemoteBuilds(
      workspaceId,
      versionId,
      environment,
      limit,
      0,
      undefined,
      undefined,
      ["documentation"],
      createdByUserId,
      status
    )

    return data.map((d) => new ExportBuild(d))
  }

  async buildLogs(
    workspaceId: string,
    buildId: string
  ): Promise<Array<ExportBuildResultLog>> {
    const data = await this.read.getRemoteBuildLogs(workspaceId, buildId)

    return data.map((d) => new ExportBuildResultLog(d))
  }

  /** Create a new exporter build */
  async createExporterBuild(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
    exporterId: string,
    destinations: ExporterJobDestination,
    brandId: string | undefined,
    themePersistentIds: Array<string> | undefined,
    exporterConfigurationProperties: DTOExporterPropertyValueMap | undefined
  ): Promise<ExportBuild> {
    const data = await this.write.createExporterBuild(
      workspaceId,
      designSystemId,
      versionId,
      exporterId,
      destinations,
      brandId,
      themePersistentIds,
      exporterConfigurationProperties
    )

    return new ExportBuild(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Workspaces

  async workspaceProductFeatures(workspaceId: string) {
    const data = await this.read.getRemoteWorkspaceProductFeatures(workspaceId)

    return new SubscriptionProductFeatures(data)
  }

  /** Download information about a single workspace and retrieve it resolved */
  async workspace(workspaceId: string): Promise<Workspace> {
    const data = await this.read.getRemoteWorkspaceDetail(workspaceId)

    return new Workspace(data)
  }

  /** Download information about a single workspace's integrations' */
  async workspaceIntegrations(
    workspaceId: string
  ): Promise<WorkspaceIntegrations> {
    const data = await this.read.getRemoteWorkspaceIntegrations(workspaceId)
    return new WorkspaceIntegrations(data)
  }

  /** Download redirect url for oauth of specific workspace integration */
  async workspaceIntegrationRedirectUrl({
    workspaceId,
    type,
  }: {
    workspaceId: string
    type: OAuth2IntegrationTypes
  }): Promise<WorkspaceIntegrationOauthRequestRemoteModel> {
    const data = await this.read.getRemoteWorkspaceIntegrationRedirectUrl({
      workspaceId,
      type,
    })
    return new WorkspaceIntegrationOauthRequest(data)
  }

  /** Creates a new credential for a workspace integration via PAT */
  async createWorkspaceIntegrationAccessToken({
    workspaceId,
    payload,
  }: {
    workspaceId: string
    payload: WorkspaceIntegrationAccessTokenPayload
  }): Promise<WorkspaceIntegrationsRemoteModel> {
    const data = await this.write.createRemoteWorkspaceIntegrationAccessToken(
      workspaceId,
      payload
    )
    return new WorkspaceIntegrations(data)
  }

  /** Delete workspace credential's integration connection */
  async deleteWorkspaceIntegrationCredential({
    workspaceId,
    integrationId,
    credentialId,
  }: {
    workspaceId: string
    integrationId: string
    credentialId: string
  }): Promise<WorkspaceIntegrationType> {
    const data = await this.write.deleteRemoteWorkspaceIntegrationCredential(
      workspaceId,
      integrationId,
      credentialId
    )
    return new WorkspaceIntegration(data)
  }

  /** Delete workspace integration */
  async deleteWorkspaceIntegration({
    workspaceId,
    integrationId,
  }: {
    workspaceId: string
    integrationId: string
  }): Promise<WorkspaceIntegrationType> {
    const data = await this.write.deleteRemoteWorkspaceIntegration(
      workspaceId,
      integrationId
    )
    return new WorkspaceIntegration(data)
  }

  /** Fetch git providers's organization */
  async fetchGitProvidersOrganization({
    workspaceId,
    integrationId,
  }: {
    workspaceId: string
    integrationId: string
  }): Promise<GitProvidersOrganizations> {
    const data = await this.read.getRemoteWorkspaceIntegrationGitProvidersData({
      workspaceId,
      integrationId,
      operation: GitProviderEndpoints.Organizations,
    })
    return new GitProvidersOrganizations(
      data as GitProvidersOrganizationsRemoteModel
    )
  }

  /** Fetch git providers's projects */
  async fetchGitProviderProjects({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersProjects> {
    const data = await this.read.getRemoteWorkspaceIntegrationGitProvidersData({
      workspaceId,
      integrationId,
      operation: GitProviderEndpoints.Projects,
      params,
    })
    return new GitProvidersProjects(data as GitProvidersProjectsRemoteModel)
  }

  /** Fetch git providers's repositories */
  async fetchGitProviderRepositories({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersRepositories> {
    const data = await this.read.getRemoteWorkspaceIntegrationGitProvidersData({
      workspaceId,
      integrationId,
      operation: GitProviderEndpoints.Repositories,
      params,
    })
    return new GitProvidersRepositories(
      data as GitProvidersRepositoriesRemoteModel
    )
  }

  /** Fetch git providers's branches */
  async fetchGitProviderBranches({
    workspaceId,
    integrationId,
    params,
  }: {
    workspaceId: string
    integrationId: string
    params?: WorkspaceIntegrationGetGitProvidersInput
  }): Promise<GitProvidersBranches> {
    const data = await this.read.getRemoteWorkspaceIntegrationGitProvidersData({
      workspaceId,
      integrationId,
      operation: GitProviderEndpoints.Branches,
      params,
    })
    return new GitProvidersBranches(data as GitProviderBranchesRemoteModel)
  }

  async createWorkspace(name: string): Promise<Workspace> {
    const data = await this.write.createRemoteWorkspace(name)

    return new Workspace(data)
  }

  async createVersion(
    designSystemId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<DesignSystemVersionCreate> {
    const data = await this.write.createRemoteVersion(designSystemId, model)

    return new DesignSystemVersionCreate(data)
  }

  async editVersion(
    designSystemId: string,
    versionId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<void> {
    await this.write.updateRemoteVersion(designSystemId, versionId, model)
  }

  async deleteVersion(
    designSystemId: string,
    versionId: string
  ): Promise<void> {
    await this.write.deleteRemoteVersion(designSystemId, versionId)
  }

  async createBrand(
    designSystemId: string,
    versionId: any,
    model: BrandUpdateModel
  ): Promise<Brand> {
    const data = await this.write.createRemoteBrand(
      designSystemId,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      versionId,
      model
    )

    return new Brand(data)
  }

  async editBrand(
    designSystemId: string,
    versionId: string,
    brandId: string,
    brand: Brand
  ): Promise<void> {
    await this.write.updateRemoteBrand(
      designSystemId,
      versionId,
      brandId,
      brand
    )
  }

  async deleteBrand(
    designSystemId: string,
    versionId: string,
    brandId: string
  ): Promise<void> {
    await this.write.deleteRemoteBrand(designSystemId, versionId, brandId)
  }

  async deleteCustomDomain(designSystemId: string): Promise<void> {
    await this.write.deleteRemoteCustomDomain(designSystemId)
  }

  async updateCustomDomain(
    designSystemId: string,
    customerDomain: string
  ): Promise<CustomDomain> {
    const data = await this.write.updateRemoteCustomDomain(
      designSystemId,
      customerDomain
    )

    return new CustomDomain(data)
  }

  async startSslGenerationForCustomDomain(
    designSystemId: string
  ): Promise<void> {
    await this.write.startSslGenerationForCustomDomain(designSystemId)
  }

  async workspaceOnboarding(workspaceId: string): Promise<WorkspaceOnboarding> {
    const data = await this.read.getRemoteWorkspaceOnboarding(workspaceId)

    // @ts-expect-error TS(2345): Argument of type 'WorkspaceOnboardingRemoteModel |... Remove this comment to see the full error message
    return new WorkspaceOnboarding(data)
  }

  async workspaceIPWhitelistSettings(
    workspaceId: string
  ): Promise<WorkspaceIPWhitelistSettings | null> {
    const data = await this.read.getRemoteWorkspaceIPWhitelistSettings(
      workspaceId
    )

    return data ? new WorkspaceIPWhitelistSettings(data) : null
  }

  async workspaceNPMRegistrySettings(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistrySettings | null> {
    const data = await this.read.getRemoteWorkspaceNPMRegistrySettings(
      workspaceId
    )

    return data ? new WorkspaceNPMRegistrySettings(data) : null
  }

  async workspaceNPMRegistryAccessToken(
    workspaceId: string
  ): Promise<WorkspaceNPMRegistryAccessTokenRemoteModel | null> {
    const data = await this.read.getRemoteWorkspaceNPMRegistryAccessToken(
      workspaceId
    )

    return data
  }

  async workspaceSSOSettings(
    workspaceId: string
  ): Promise<WorkspaceSSOSettings | null> {
    const data = await this.read.getRemoteWorkspaceSSOSettings(workspaceId)

    return data ? new WorkspaceSSOSettings(data) : null
  }

  async workspaceInvitations(
    workspaceId: string
  ): Promise<Array<WorkspaceInvitation>> {
    const data = await this.read.getRemoteWorkspaceInvitations(workspaceId)

    return data.map((m) => new WorkspaceInvitation(m))
  }

  async workspaceInvoices(
    workspaceId: string
  ): Promise<Array<WorkspaceInvoice>> {
    const data = await this.read.getRemoteWorkspaceInvoices(workspaceId)

    return data.map((m) => new WorkspaceInvoice(m))
  }

  async workspaceSubscription(
    workspaceId: string
  ): Promise<WorkspaceSubscription> {
    const data = await this.read.getRemoteWorkspaceSubscription(workspaceId)

    return new WorkspaceSubscription(data)
  }

  async workspaceAvailableProducts(
    workspaceId: string
  ): Promise<Array<WorkspaceProduct>> {
    const data = await this.read.getRemoteWorkspaceAvailableProducts(
      workspaceId
    )

    return data.map((m) => new WorkspaceProduct(m))
  }

  async updateWorkspaceProfile(
    workspaceId: string,
    profile: WorkspaceProfileUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceProfile(workspaceId, profile)
  }

  async testIpWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<{
    accessible: boolean
  }> {
    return this.write.testIpWhitelistSettings(workspaceId, ipWhitelistSettings)
  }

  async updateWorkspaceOnboarding(
    workspaceId: string,
    state: WorkspaceOnboardingUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceOnboarding(workspaceId, state)
  }

  async updateWorkspaceAvatar(
    workspaceId: string,
    avatar: File
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceAvatar(workspaceId, avatar)
  }

  async updateWorkspaceSSOSettings(
    workspaceId: string,
    ssoSettings: WorkspaceSSOSettingsUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceSSOSettings(workspaceId, ssoSettings)
  }

  async updateWorkspaceIPWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceIPWhitelistSettings(
      workspaceId,
      ipWhitelistSettings
    )
  }

  async updateWorkspaceNPMRegistrySettings(
    workspaceId: string,
    npmRegistrySettings: WorkspaceNPMRegistrySettingsUpdateModel
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceNPMRegistrySettings(
      workspaceId,
      npmRegistrySettings
    )
  }

  async updateUserWorkspaceNotificationSettings(
    userId: string,
    workspaceId: string,
    notificationSettings: UserWorkspaceNotificationSettingsUpdateModel
  ): Promise<void> {
    return this.write.updateUserWorkspaceNotificationSettings(
      userId,
      workspaceId,
      notificationSettings
    )
  }

  async updateWorkspaceOwnership(
    workspaceId: string,
    newOwnerUserId: string
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceOwnership(
      workspaceId,
      newOwnerUserId
    )
  }

  async updateWorkspaceMemberRole(
    workspaceId: string,
    userId: string,
    newRole: UserRole
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceMemberRole(
      workspaceId,
      userId,
      newRole
    )
  }

  async updateWorkspaceInvitationRole(
    workspaceId: string,
    invitationId: string,
    newRole: UserRole
  ): Promise<void> {
    return this.write.updateRemoteWorkspaceInvitationRole(
      workspaceId,
      invitationId,
      newRole
    )
  }

  async addWorkspaceInvitations(
    workspaceId: string,
    invitations: Array<WorkspaceInvitationUpdateModel>,
    designSystemId: string | undefined
  ): Promise<void> {
    return this.write.addRemoteWorkspaceInvitations(
      workspaceId,
      invitations,
      designSystemId
    )
  }

  async deleteWorkspace(workspaceId: string): Promise<void> {
    return this.write.deleteRemoteWorkspace(workspaceId)
  }

  async deleteWorkspaceAvatar(workspaceId: string): Promise<void> {
    return this.write.deleteRemoteWorkspaceAvatar(workspaceId)
  }

  async deleteWorkspaceNPMRegistrySettings(workspaceId: string): Promise<void> {
    return this.write.deleteRemoteWorkspaceNPMRegistrySettings(workspaceId)
  }

  async deleteWorkspaceMember(
    workspaceId: string,
    userId: string
  ): Promise<void> {
    return this.write.deleteRemoteWorkspaceMember(workspaceId, userId)
  }

  async deleteWorkspaceInvitation(
    workspaceId: string,
    invitationId: string
  ): Promise<void> {
    return this.write.deleteRemoteWorkspaceInvitation(workspaceId, invitationId)
  }

  async createCheckoutSession(
    workspaceId: string,
    priceId: string,
    successUrl: string,
    cancelUrl: string,
    quantity?: number | null
  ): Promise<string> {
    return this.write.createRemoteCheckoutSession(
      workspaceId,
      priceId,
      successUrl,
      cancelUrl,
      quantity
    )
  }

  async createPortalSession(
    workspaceId: string,
    returnUrl: string,
    update?: WorkspaceSubscriptionUpdateModel | null,
    cancel?: boolean | null
  ): Promise<string> {
    return this.write.createRemotePortalSession(
      workspaceId,
      returnUrl,
      update,
      cancel
    )
  }

  async resendWorkspaceInvitation(
    workspaceId: string,
    invitationId: string,
    designSystemId: string | undefined
  ): Promise<void> {
    return this.write.resendRemoteWorkspaceInvitation(
      workspaceId,
      invitationId,
      designSystemId
    )
  }

  async restoreWorkspaceSubscription(workspaceId: string): Promise<void> {
    return this.write.restoreRemoteWorkspaceSubscription(workspaceId)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Design systems

  /** Download information about a single design system and retrieve it resolved */
  async designSystem(designSystemId: string): Promise<DesignSystem> {
    const data = await this.read.getRemoteDesignSystem(designSystemId)

    return new DesignSystem(data)
  }

  /** Download information about design systems that user has access to under a single workspace and retrieve it resolved */
  async designSystems(workspaceId: any): Promise<Array<DesignSystem>> {
    const data = await this.read.getRemoteDesignSystems(workspaceId)

    return data.map((d) => new DesignSystem(d))
  }

  /** Creates a design system */
  async createDesignSystem(
    workspaceId: string,
    name: string,
    description?: string,
    accessMode?: DesignSystemAccessMode,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>
  ): Promise<DesignSystem> {
    const data = await this.write.createRemoteDesignSystem(
      workspaceId,
      name,
      description,
      accessMode,
      usersToInvite,
      invitesToInvite,
      emailsToInvite
    )

    return new DesignSystem(data)
  }

  async getBlockDefinitions(
    designSystemId: string,
    versionId: string
  ): Promise<Array<DocsBlockDefinition>> {
    const data = await this.read.getBlockDefinitions(designSystemId, versionId)

    return data.map((item) => {
      return new DocsBlockDefinition(item)
    })
  }

  async getUrlPreview(
    designSystemId: string,
    versionId: string,
    request: DTODocumentationLinkPreviewRequest
  ) {
    return this.read.getUrlPreview(designSystemId, versionId, request)
  }

  /** Updates a design system metadata */
  async updateDesignSystemMetadata(
    designSystemId: string,
    name: string,
    description?: string
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemMetadata(
      designSystemId,
      name,
      description
    )
  }

  /** Updates a design system multibrand option */
  async updateDesignSystemIsMultibrand(
    designSystem: DesignSystem,
    isMultibrand: boolean
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemIsMultibrand(
      designSystem,
      isMultibrand
    )
  }

  /** Updates a design system approval feature */
  async updateDesignSystemApprovalsFeature(
    designSystemId: string,
    payload: {
      isApprovalFeatureEnabled?: boolean
      approvalRequiredForPublishing?: boolean
    }
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemApprovalsFeature(
      designSystemId,
      payload
    )
  }

  /** Updates a design system switcher */
  async updateDesignSystemSwitcher(
    designSystem: DesignSystem,
    designSystemSwitcher: DesignSystemSwitcher
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemSwitcher(
      designSystem,
      designSystemSwitcher
    )
  }

  /** Updates design system doc exporter id  */
  async updateDesignSystemDocExporterId(
    designSystem: DesignSystem,
    docExporterId: string
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemDocExporterId(
      designSystem,
      docExporterId
    )
  }

  /** Updates a design system multibrand option */
  async updateDesignSystemDocumentationSlug(
    designSystem: DesignSystem,
    docUserSlug: string
  ): Promise<void> {
    await this.write.updateRemoteDesignSystemDocumentationSlug(
      designSystem,
      docUserSlug
    )
  }

  /** Delete a design system */
  async deleteDesignSystem(designSystemId: string): Promise<void> {
    await this.write.deleteDesignSystem(designSystemId)
  }

  /** Download information about a custom domain */
  async designSystemCustomDomain(
    designSystemId: string
  ): Promise<CustomDomain> {
    const data = await this.read.getRemoteCustomDomain(designSystemId)

    return new CustomDomain(data)
  }

  /** Get design system stats */
  async designSystemStats(dsId: string, versionId: string, brandId?: string) {
    const data = await this.read.getRemoteDesignSystemStats(
      dsId,
      versionId,
      brandId
    )

    return new DesignSystemVersionStats(data)
  }

  /** Download information about a desing system source within a design system design and retrieve it resolved */
  async designSystemSource(
    designSystemId: string,
    sourceId: string
  ): Promise<Source> {
    const data = await this.read.getRemoteSource(designSystemId, sourceId)

    return new Source(data)
  }

  /** Download information about all design system sources within a design system design and retrieve it resolved */
  async designSystemSources(designSystemId: string): Promise<Array<Source>> {
    const data = await this.read.getRemoteSources(designSystemId)

    return data.map((d) => new Source(d))
  }

  /** Download information about all design system members */
  async designSystemMemberList(
    designSystemId: string
  ): Promise<DesignSystemMemberList> {
    const data = await this.read.getRemoteDesignSystemMemberList(designSystemId)

    return new DesignSystemMemberList(data)
  }

  /** Download information about design system's contacts */
  async designSystemContacts(
    designSystemId: string
  ): Promise<DesignSystemContactList> {
    const data = await this.read.getRemoteContacts(designSystemId)

    return new DesignSystemContactList(data)
  }

  async getFigmaNodeStructures(
    designSystemId: string,
    versionId: string
  ): Promise<Array<FigmaStructure>> {
    const data = await this.read.getFigmaNodeStructures(
      designSystemId,
      versionId
    )

    return data.map((model) => new FigmaStructure(model))
  }

  async getFigmaNodeStructureDetail(
    designSystemId: string,
    versionId: string,
    figmaStructureId: string
  ): Promise<FigmaStructureDetail> {
    const data = await this.read.getFigmaNodeStructureDetail(
      designSystemId,
      versionId,
      figmaStructureId
    )

    return new FigmaStructureDetail(data)
  }

  async deleteFigmaSource(
    designSystemId: string,
    sourceId: string
  ): Promise<void> {
    return this.write.deleteRemoteSource(designSystemId, sourceId)
  }

  async linkFigmaSource(
    designSystemId: string,
    brandId: string,
    url: string,
    options: {
      scopes: {
        tokens: boolean
        components: boolean
        assets: boolean
        documentationFrames: boolean
        isUnpublishedContentFallbackEnabled: boolean
      }
      autoImport: SourceRemoteModelCloud["autoImportMode"]
      themeId: string | null
    }
  ) {
    const payload: any = {
      figmaFileUrl: url,
      autoImportMode: options.autoImport,
      brandId,
      scope: options.scopes,
    }

    if (options.themeId) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.themeId = options.themeId
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await this.write.linkFigmaSource(designSystemId, payload)

    return new Source(data)
  }

  async updateFigmaSource(
    designSystemId: string,
    sourceId: string,
    options: {
      scopes?: {
        tokens: boolean
        components: boolean
        assets: boolean
        documentationFrames: boolean
      }
      autoImport?: SourceRemoteModelCloud["autoImportMode"]
      themeId?: string | null
    }
  ) {
    const payload: any = {}

    if (options.scopes) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.scope = options.scopes
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-prototype-builtins
    if (options.hasOwnProperty("autoImport")) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.autoImportMode = options.autoImport
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line no-prototype-builtins
    if (options.hasOwnProperty("themeId")) {
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.themeId = options.themeId
    }

    const data = await this.write.updateFigmaSource(
      designSystemId,
      sourceId,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      payload
    )

    return new Source(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Versions

  async versions(designSystemId: string): Promise<Array<DesignSystemVersion>> {
    const data = await this.read.getVersions(designSystemId)

    return data.map((d) => new DesignSystemVersion(d))
  }

  async version(
    designSystemId: string,
    versionId: string
  ): Promise<DesignSystemVersion> {
    const data = await this.read.getVersion(designSystemId, versionId)

    return new DesignSystemVersion(data)
  }

  async versionCreationJob(
    designSystemId: string,
    jobId: string
  ): Promise<DesignSystemVersionCreationJob> {
    const data = await this.read.getVersionCreationJob(designSystemId, jobId)

    return new DesignSystemVersionCreationJob(data)
  }

  async versionCreationJobs(
    designSystemId: string
  ): Promise<DesignSystemVersionCreationJob[]> {
    const jobs = await this.read.getVersionCreationJobs(designSystemId)

    return jobs.map((job) => new DesignSystemVersionCreationJob(job))
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Brands

  async getBrands(
    designSystemId: string,
    versionId: string
  ): Promise<Array<Brand>> {
    const data = await this.read.getBrands(designSystemId, versionId)

    return data.map((d) => new Brand(d))
  }

  async getBrand(
    designSystemId: string,
    versionId: string,
    brandId: string
  ): Promise<Brand> {
    const data = await this.read.getBrand(designSystemId, versionId, brandId)

    return new Brand(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Integrations

  getAuthenticationTokens() {
    return this.read.getAuthenticationTokens()
  }

  deleteAuthenticationToken(id: string) {
    return this.write.deleteAuthenticationToken(id)
  }

  createAuthenticationToken(name: string, scope?: string) {
    return this.write.createAuthenticationToken(name, scope)
  }

  getOAuthURL(service: "figma" | ExporterProvider): Promise<string> {
    return this.read.getOAuthURL(service)
  }

  deleteOAuthIntegration(
    userId: string,
    service: "figma" | ExporterProvider
  ): Promise<void> {
    return this.write.deleteOAuthIntegration(userId, service)
  }

  createPersonalAccessTokenConnection(
    service: ConnectionProviders,
    connection:
      | PersonalAccessTokenConnectionPayload
      | AzurePersonalAccessTokenConnectionPayload
  ): Promise<ConnectionRemoteModel> {
    return this.write.createPersonalAccessTokenConnection(service, connection)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Transactions

  async bulkUpdateTokenData(
    designSystemId: string,
    versionId: string,
    data: TokenUpdateTransactionData
  ): Promise<any> {
    let primaryPromise = Promise.resolve()

    if (data.tokenMode === "create") {
      // Create token if it didn't exist. We don't have to update the top level group, because BE will do that for us
      const ids = await this.createToken(
        designSystemId,
        versionId,
        data.token,
        data.tokenParent.id
      )

      data.token.id = ids.id
      data.token.idInVersion = ids.idInVersion
    } else if (data.tokenMode === "update") {
      // Update token if it is not imported.
      // In this case, also update group where it belongs
      // but make sure that `this.updateToken` runs first and `this.updateTokenGroup` after it

      if (!TokenUtils.isTokenImported(data.token)) {
        primaryPromise = primaryPromise.then(() =>
          this.updateToken(designSystemId, versionId, data.token)
        )
      }

      primaryPromise = primaryPromise.then(() =>
        this.updateTokenGroup(designSystemId, versionId, data.tokenParent)
      )
    }

    // Add all secondary updates
    const secondaryPromises = new Array<() => Promise<any>>()

    if (data.propertyValuesToCreateOrUpdate) {
      data.propertyValuesToCreateOrUpdate.forEach((p) => {
        secondaryPromises.push(() =>
          this.updateTokenPropertyValue(
            designSystemId,
            versionId,
            p.value,
            data.token,
            p.property
          )
        )
      })
    }

    if (data.propertyValuesToDelete) {
      data.propertyValuesToDelete.forEach((p) => {
        secondaryPromises.push(() =>
          this.deleteTokenPropertyValue(designSystemId, versionId, p)
        )
      })
    }

    if (data.themeValuesToCreate) {
      data.themeValuesToCreate.forEach((themeOverride) => {
        secondaryPromises.push(() =>
          this.createThemeOverrideForToken(
            designSystemId,
            versionId,
            themeOverride.themeId,
            themeOverride.themeOverrideTransferObject
          )
        )
      })
    }

    if (data.themeValuesToUpdate) {
      data.themeValuesToUpdate.forEach((themeOverride) => {
        secondaryPromises.push(() =>
          this.updateThemeOverrideForToken(
            designSystemId,
            versionId,
            data.token.id,
            themeOverride.themeId,
            themeOverride.themeOverrideTransferObject
          )
        )
      })
    }

    if (data.themeValuesToDelete) {
      data.themeValuesToDelete.forEach((themeId) => {
        secondaryPromises.push(() =>
          this.deleteThemeOverrideForToken(
            designSystemId,
            versionId,
            data.token.id,
            themeId
          )
        )
      })
    }

    // NOTE: have the primary promise run first and secondary promises run after that
    await primaryPromise
    await Promise.all(secondaryPromises.map((p) => p()))
  }

  async bulkUpdateComponentData(
    designSystemId: string,
    versionId: string,
    data: ComponentUpdateTransactionData
  ): Promise<any> {
    let primaryPromise = Promise.resolve()

    if (data.componentMode === "create") {
      // Create component if it didn't exist. We don't have to update the top level group, because BE will do that for us
      const ids = await this.createComponent(
        designSystemId,
        versionId,
        data.component
        // data.componentParent.id
      )

      data.component.id = ids.id
      data.component.idInVersion = ids.idInVersion
    } else if (data.componentMode === "update") {
      // Update token because it did exist. In this case, also update group where it belongs
      // but make sure that `this.updateToken` runs first and `this.updateTokenGroup` after it
      primaryPromise = primaryPromise.then(() =>
        this.updateComponent(designSystemId, versionId, data.component)
      )
      // .then(() =>
      //   this.updateComponentGroup(
      //     designSystemId,
      //     versionId,
      //     data.componentParent
      //   )
      // )
    }

    // Add all secondary updates
    const secondaryPromises = new Array<Promise<any>>()

    if (data.propertyValuesToCreateOrUpdate) {
      data.propertyValuesToCreateOrUpdate.forEach((p) => {
        secondaryPromises.push(
          this.updateComponentPropertyValue(
            designSystemId,
            versionId,
            p.value,
            data.component,
            p.property
          )
        )
      })
    }

    if (data.propertyValuesToDelete) {
      data.propertyValuesToDelete.forEach((p) => {
        secondaryPromises.push(
          this.deleteTokenPropertyValue(designSystemId, versionId, p)
        )
      })
    }

    // NOTE: have the primary promise run first and secondary promises run after that
    await primaryPromise
    await Promise.all(secondaryPromises)
  }

  async importTokens(
    designSystemId: string,
    brandId: string,
    sourceId: string,
    sourceName: string,
    sourceType: "FigmaVariablesPlugin",
    payload: any,
    isTokenTypeSplitEnabled?: boolean
  ): Promise<ImportJob> {
    const data = await this.write.importTokens(
      designSystemId,
      brandId,
      sourceId,
      sourceName,
      sourceType,
      "Upload",
      payload,
      isTokenTypeSplitEnabled
    )

    return new ImportJob(data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Automation

  async getImportJob(
    designSystemId: string,
    versionId: string,
    jobId: string
  ): Promise<ImportJob> {
    const data = await this.read.getImportJob(designSystemId, versionId, jobId)

    return new ImportJob(data)
  }

  async getImportJobs(
    designSystemId: string,
    versionId: string
  ): Promise<Array<ImportJob>> {
    const data = await this.read.getImportJobs(designSystemId, versionId)

    return data.map((d) => new ImportJob(d))
  }

  async scheduleImportJobForFigmaSources(
    designSystemId: string,
    versionId: string,
    sourceIds: Array<string>,
    dryRun: boolean
  ): Promise<ImportJob> {
    const data = await this.write.scheduleImportJobForFigmaSources(
      designSystemId,
      versionId,
      sourceIds,
      dryRun
    )

    return new ImportJob(data)
  }

  async getTokenStudioData(
    designSystemId: string,
    versionId: string
  ): Promise<object> {
    return this.read.getTokenStudioData(designSystemId, versionId)
  }

  async writeTokenStudioData(
    designSystemId: string,
    versionId: string,
    data: object
  ): Promise<object> {
    return this.write.writeTokenStudioJSONData(designSystemId, versionId, data)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Data storage

  async readKeyValueStorageData(
    designSystemId: string,
    key: string
  ): Promise<string | object | undefined> {
    const data = await this.read.getKeyValueStorageData(designSystemId, [key])

    return data
  }

  async setKeyValueStorageData(
    designSystemId: string,
    key: string,
    value: string | object
  ): Promise<void> {
    const stringValue =
      typeof value === "string" ? value : JSON.stringify(value, null, 0)

    await this.write.setKeyValueStorageData(designSystemId, {
      metadata: {
        [key]: stringValue,
      },
    })
  }

  async unsetKeyValueStorageData(
    designSystemId: string,
    key: string
  ): Promise<void> {
    await this.write.setKeyValueStorageData(designSystemId, {
      metadata: { [key]: null },
    })
  }
}
