/* eslint-disable max-lines */
//
//  SDKDataCoreWriteRequests.ts
//  Supernova SDK
//
//  Created by Jiri Trecak.
//  Copyright © 2020 Supernova. All rights reserved.
//
// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Imports
import {
  DTOCreateDocumentationGroupInput,
  DTOCreateDocumentationPageInputV2,
  DTOCreateDocumentationTabInput,
  DTODeleteDocumentationGroupInput,
  DTODeleteDocumentationPageInputV2,
  DTODeleteDocumentationTabGroupInput,
  DTODesignSystem,
  DTODesignSystemCreateInput,
  DTODesignSystemMembersUpdatePayload,
  DTODesignSystemUpdateAccessModeInput,
  DTODocumentationPageApprovalStateChangeInput,
  DTODuplicateDocumentationGroupInput,
  DTODuplicateDocumentationPageInputV2,
  DTOElementActionInput,
  DTOExporterPropertyValueMap,
  DTOFigmaNode,
  DTOFigmaNodeRenderIdInput,
  DTOFigmaNodeRenderInput,
  DTOMoveDocumentationGroupInput,
  DTOMoveDocumentationPageInputV2,
  DTOPipeline,
  DTOPipelineCreateBody,
  DTOPipelineUpdateBody,
  DTOPublishDocumentationRequest,
  DTORestoreDocumentationGroupInput,
  DTORestoreDocumentationPageInput,
  DTOUpdateDocumentationGroupInput,
  DTOUpdateDocumentationPageInputV2,
} from "@supernova-studio/client"
import {
  DesignSystemInvite,
  DesignSystemInviteUpdate,
  DesignSystemMemberUpdate,
  DesignSystemPendingMemberInvite,
  DesignSystemUserInvitation,
} from "@supernova-studio/model"

import {
  DesignSystemAccessMode,
  ExporterJobDestination,
  Pipeline,
  TokenThemeOverrideTranserObject,
  UserRole,
} from "../../../exports"
import {
  Brand,
  BrandRemoteModel,
  BrandUpdateModel,
} from "../../../model/base/SDKBrand"
import {
  DesignSystem,
  DesignSystemSwitcher,
} from "../../../model/base/SDKDesignSystem"
import {
  DesignSystemVersionCreateRemoteModel,
  DesignSystemVersionUpdateModel,
} from "../../../model/base/SDKDesignSystemVersion"
import { DocumentationLegacyModel } from "../../../model/base/SDKDocumentationLegacy"
import {
  Component,
  ComponentRemoteModel,
  ComponentUpdateRemoteModel,
} from "../../../model/components/SDKComponent"
import { ElementDataViewColumnType } from "../../../model/elements/SDKElementDataViewColumn"
import { ElementPropertyRemoteModel } from "../../../model/elements/SDKElementProperty"
import { ConnectionProviders } from "../../../model/enums/SDKConnectionProviders"
import { DocumentationEnvironment } from "../../../model/enums/SDKDocumentationEnvironment"
import {
  AuthenticationTokenPayload,
  AzurePersonalAccessTokenConnectionPayload,
  CreateAuthenticationTokenPayload,
  PersonalAccessTokenConnectionPayload,
} from "../../../model/exporters/SDKConnections"
import {
  ExporterProvider,
  NewExporterPayload,
} from "../../../model/exporters/SDKExporter"
import { ExportBuildModel } from "../../../model/exporters/exporter_builds/SDKExportBuild"
import { ConnectionRemoteModel } from "../../../model/exporters/remote/SDKRemoteConnectionsModel"
import {
  ComponentGroup,
  ComponentGroupRemoteModel,
} from "../../../model/groups/SDKComponentGroup"
import {
  TokenGroup,
  TokenGroupRemoteModel,
} from "../../../model/groups/SDKTokenGroup"
import { ImportJobRemoteModel } from "../../../model/jobs/SDKImportJob"
import { ResourceAssetModel } from "../../../model/resources/SDKResourceAsset"
import { ResourceFontModel } from "../../../model/resources/SDKResourceFont"
import { CustomDomainRemoteModel } from "../../../model/support/SDKCustomDomain"
import { SourceRemoteModel } from "../../../model/support/SDKSource"
import { TokenThemeRemoteModel } from "../../../model/themes/SDKTokenTheme"
import { Token } from "../../../model/tokens/SDKToken"
import { TokenRemoteModel } from "../../../model/tokens/remote/SDKRemoteTokenModel"
import { UserProfileUpdateModel } from "../../../model/users/SDKUserProfile"
import { UserWorkspaceNotificationSettingsUpdateModel } from "../../../model/users/SDKUserWorkspaceNotificationSettings"
import { WorkspaceRemoteModel } from "../../../model/workspaces/SDKWorkspace"
import { WorkspaceIPWhitelistSettingsUpdateModel } from "../../../model/workspaces/SDKWorkspaceIPWhitelistSettings"
import {
  WorkspaceIntegrationsRemoteModel,
  WorkspaceIntegrationType,
} from "../../../model/workspaces/SDKWorkspaceIntegration"
import { WorkspaceIntegrationAccessTokenPayload } from "../../../model/workspaces/SDKWorkspaceIntegrationAccessToken"
import { WorkspaceInvitationUpdateModel } from "../../../model/workspaces/SDKWorkspaceInvitation"
import { WorkspaceNPMRegistrySettingsUpdateModel } from "../../../model/workspaces/SDKWorkspaceNPMRegistrySettings"
import { WorkspaceOnboardingUpdateModel } from "../../../model/workspaces/SDKWorkspaceOnboarding"
import { WorkspaceProfileUpdateModel } from "../../../model/workspaces/SDKWorkspaceProfile"
import { WorkspaceSSOSettingsUpdateModel } from "../../../model/workspaces/SDKWorkspaceSSOSettings"
import { WorkspaceSubscriptionUpdateModel } from "../../../model/workspaces/SDKWorkspaceSubscription"
import { DataBridge } from "../SDKDataBridge"

import { v4 as uuidv4 } from "uuid"

// --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
// MARK: - Request engine: Write

export class DataCoreWriteRequests {
  private bridge: DataBridge

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Constructor

  constructor(bridge: DataBridge) {
    this.bridge = bridge
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - User

  /** Remote: Deletes the user by id. Can only really delete its own user */
  async deleteRemoteUser(userId: string): Promise<void> {
    const endpoint = this.bridge.getUserEndpoint(userId, null)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Checks whether user can be deleted or not. Can only really check its own user */
  // TODO:fix-sdk-eslint
  // eslint-disable-next-line @typescript-eslint/require-await
  async canDeleteRemoteUser(userId: string): Promise<boolean> {
    const endpoint = this.bridge.getUserEndpoint(userId, "check-deletable")
    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = this.bridge.post(endpoint, {}) // This will throw if not deletable

    return true
  }

  /** Remote: Updates the user profile of specified user. This call can only be used on the user who is authenticated with the token */
  async updateRemoteUserProfile(
    profile: UserProfileUpdateModel,
    userId: string
  ): Promise<void> {
    const endpoint = this.bridge.getUserEndpoint(userId, "profile")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, profile)
  }

  /** Remote: Updates the avatar of the current user */
  async updateRemoteUserAvatar(userId: string, avatar: File): Promise<void> {
    const endpoint = this.bridge.getUserEndpoint(userId, "avatar")
    const formData = new FormData()

    formData.append("avatar", avatar)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.upload(endpoint, formData)
  }

  /** Remote: Updates the avatar of the current user */
  async deleteRemoteUserAvatar(userId: string): Promise<void> {
    const endpoint = this.bridge.getUserEndpoint(userId, "avatar")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Logs out user. Can only really logout its own user */
  async logoutRemoteCurrentUser(): Promise<void> {
    const endpoint = this.bridge.getBaseEndpoint("users/me/logout")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, null)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Workspace

  /** Remote: Creates a new workspace */
  async createRemoteWorkspace(name: string): Promise<WorkspaceRemoteModel> {
    const endpoint = this.bridge.getBaseEndpoint("workspaces")

    const payload = await this.bridge.post(endpoint, {
      name,
      product: "free",
      priceId: "yearly",
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.workspace as WorkspaceRemoteModel
  }

  /** Remote: Creates a new version */
  async createRemoteVersion(
    designSystemId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<DesignSystemVersionCreateRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "head/commit"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await this.bridge.post(endpoint, {
      meta: {
        name: model.name ?? "",
        description: model.description ?? "",
      },
      version: model.version,
      changeLog: model.changeLog ?? "",
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.result as DesignSystemVersionCreateRemoteModel
  }

  /** Remote: Updates a version */
  async updateRemoteVersion(
    designSystemId: string,
    versionId: string,
    model: DesignSystemVersionUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      null
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, {
      meta: {
        name: model.name ?? "",
        description: model.description ?? "",
      },
      version: model.version,
      changeLog: model.changeLog ?? "",
    })
  }

  /** Remote: Deletes a version */
  async deleteRemoteVersion(
    designSystemId: string,
    versionId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      null
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Creates a new brand */
  async createRemoteBrand(
    designSystemId: string,
    versionId: string,
    model: BrandUpdateModel
  ): Promise<BrandRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "brands"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await this.bridge.post(endpoint, {
      meta: {
        name: model.name ?? "",
        description: model.description ?? "",
      },
      persistentId: uuidv4(),
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.result.brand as BrandRemoteModel
  }

  /** Remote: Updates a brand */
  async updateRemoteBrand(
    designSystemId: string,
    versionId: string,
    brandId: string,
    brand: Brand
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `brands/${brandId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, brand.toRemote())
  }

  /** Remote: Deletes a brand */
  async deleteRemoteBrand(
    designSystemId: string,
    versionId: string,
    brandId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `brands/${brandId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Updates custom domain */
  async updateRemoteCustomDomain(
    designSystemId: string,
    customerDomain: string
  ): Promise<CustomDomainRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "custom-domain"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.put(endpoint, { customerDomain })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return payload.result.customDomain
  }

  /** Remote: Deletes custom domain */
  async deleteRemoteCustomDomain(designSystemId: string): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "custom-domain"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async startSslGenerationForCustomDomain(
    designSystemId: string
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "custom-domain/ssl"
    )

    return this.bridge.post(endpoint, {})
  }

  /** Remote: Updates a workspace profile */
  async updateRemoteWorkspaceProfile(
    workspaceId: string,
    profile: WorkspaceProfileUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "profile")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, profile)
  }

  async testIpWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<{ accessible: boolean }> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "ip-whitelist-test"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await this.bridge.post(endpoint, ipWhitelistSettings)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return response.result
  }

  /** Remote: Updates a workspace avatar */
  async updateRemoteWorkspaceAvatar(
    workspaceId: string,
    avatar: File
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "avatar")
    const formData = new FormData()

    formData.append("avatar", avatar)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.upload(endpoint, formData)
  }

  /** Remote: Updates a workspace onboarding */
  async updateRemoteWorkspaceOnboarding(
    workspaceId: string,
    onboarding: WorkspaceOnboardingUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "onboarding")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, onboarding)
  }

  /** Remote: Updates a workspace SSO settings */
  async updateRemoteWorkspaceSSOSettings(
    workspaceId: string,
    ssoSettings: WorkspaceSSOSettingsUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "sso")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, ssoSettings)
  }

  /** Remote: Updates a workspace IP whitelist settings */
  async updateRemoteWorkspaceIPWhitelistSettings(
    workspaceId: string,
    ipWhitelistSettings: WorkspaceIPWhitelistSettingsUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "ip-whitelist"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, ipWhitelistSettings)
  }

  /** Remote: Updates a workspace NPM registry settings */
  async updateRemoteWorkspaceNPMRegistrySettings(
    workspaceId: string,
    npmRegistrySettings: WorkspaceNPMRegistrySettingsUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "npm-registry"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, npmRegistrySettings)
  }

  /** Remote: Updates a notification settings */
  async updateUserWorkspaceNotificationSettings(
    userId: string,
    workspaceId: string,
    notificationSettings: UserWorkspaceNotificationSettingsUpdateModel
  ): Promise<void> {
    const endpoint = this.bridge.getUserWorkspaceEndpoint(
      userId,
      workspaceId,
      "notifications"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, { notificationSettings })
  }

  /** Remote: Updates a workspace ownership */
  async updateRemoteWorkspaceOwnership(
    workspaceId: string,
    newOwnerUserId: string
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "ownership")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, {
      newOwnerId: newOwnerUserId,
    })
  }

  /** Remote: Updates a member role */
  async updateRemoteWorkspaceMemberRole(
    workspaceId: string,
    userId: string,
    newRole: UserRole
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "members/role"
    )

    return this.bridge.put(endpoint, {
      members: [
        {
          userId,
          role: newRole,
        },
      ],
    })
  }

  /** Remote: Updates an invitation role */
  async updateRemoteWorkspaceInvitationRole(
    workspaceId: string,
    invitationId: string,
    newRole: UserRole
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `invitations/${invitationId}/role`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, {
      role: newRole,
    })
  }

  /** Remote: Add new invitations */
  async addRemoteWorkspaceInvitations(
    workspaceId: string,
    invitations: Array<WorkspaceInvitationUpdateModel>,
    designSystemId: string | undefined
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, `members`)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, {
      invites: invitations,
      designSystemId,
    })
  }

  /** Remote: Deletes a workspace */
  async deleteRemoteWorkspace(workspaceId: string): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, null)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Deletes a workspace avatar */
  async deleteRemoteWorkspaceAvatar(workspaceId: string): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(workspaceId, "avatar")

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Deletes a workspace NPM settings */
  async deleteRemoteWorkspaceNPMRegistrySettings(
    workspaceId: string
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "npm-registry"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Deletes a workspace member */
  async deleteRemoteWorkspaceMember(
    workspaceId: string,
    userId: string
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `members/${userId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: Deletes an invitation */
  async deleteRemoteWorkspaceInvitation(
    workspaceId: string,
    invitationId: string
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `invitations/${invitationId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  /** Remote: create Checkout session */
  async createRemoteCheckoutSession(
    workspaceId: string,
    priceId: string,
    successUrl: string,
    cancelUrl: string,
    quantity?: number | null
  ): Promise<string> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `subscription/checkout-session`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, {
      priceId,
      successUrl,
      cancelUrl,
      quantity,
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.url as string
  }

  /** Remote: create Customer portal session */
  async createRemotePortalSession(
    workspaceId: string,
    returnUrl: string,
    update?: WorkspaceSubscriptionUpdateModel | null,
    cancel?: boolean | null
  ): Promise<string> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `subscription/portal-session`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, {
      returnUrl,
      ...(update && { update }),
      cancel,
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.url as string
  }

  /** Remote: Resend the invitation */
  async resendRemoteWorkspaceInvitation(
    workspaceId: string,
    invitationId: string,
    designSystemId: string | undefined
  ): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `invitations/${invitationId}/resend`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, {
      invitationId,
      designSystemId,
    })
  }

  /** Remote: Restore the cancelled subscription */
  async restoreRemoteWorkspaceSubscription(workspaceId: string): Promise<void> {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      `subscription/restore`
    )

    return this.bridge.post(endpoint, {})
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Design System

  /** Remote: Creates a new design system */
  async createRemoteDesignSystem(
    workspaceId: string,
    name: string,
    description?: string,
    accessMode?: DesignSystemAccessMode,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>
  ): Promise<DTODesignSystem> {
    const endpoint = this.bridge.getBaseEndpoint("design-systems")

    const payload = await this.bridge.post(endpoint, {
      meta: {
        name,
        description: description ?? "",
      },
      workspaceId,
      accessMode,
      invites: {
        usersToInvite,
        invitesToInvite,
        emailsToInvite,
      },
    } satisfies DTODesignSystemCreateInput)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return DTODesignSystem.parse(payload.result.designSystem)
  }

  /** Remote: Updates a design system metadata */
  async updateRemoteDesignSystemMetadata(
    designSystemId: string,
    name: string,
    description?: string
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined
    )

    await this.bridge.put(endpoint, {
      meta: {
        name,
        description: description ?? "",
      },
    })
  }

  /** Remote: Updates a design system multibrand capabilities */
  async updateRemoteDesignSystemIsMultibrand(
    designSystem: DesignSystem,
    isMultibrand: boolean
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystem.id,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined
    )

    await this.bridge.put(endpoint, {
      isMultibrand,
      meta: {
        name: designSystem.name,
        description: designSystem.description ?? "",
      },
    })
  }

  /** Remote: Updates a design system approval feature */
  async updateRemoteDesignSystemApprovalsFeature(
    designSystemId: string,
    payload: {
      isApprovalFeatureEnabled?: boolean
      approvalRequiredForPublishing?: boolean
    }
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(designSystemId, null)

    await this.bridge.put(endpoint, payload)
  }

  /** Remote: Updates a design system switcher */
  async updateRemoteDesignSystemSwitcher(
    designSystem: DesignSystem,
    designSystemSwitcher: DesignSystemSwitcher
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(designSystem.id, null)

    await this.bridge.put(endpoint, {
      meta: {
        name: designSystem.name,
        description: designSystem.description,
      },
      designSystemSwitcher,
    })
  }

  /** Remote: Updates design system doc exporter id */
  async updateRemoteDesignSystemDocExporterId(
    designSystem: DesignSystem,
    docExporterId: string
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystem.id,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined
    )

    await this.bridge.put(endpoint, {
      meta: {
        name: designSystem.name,
        description: designSystem.description,
      },
      docExporterId,
    })
  }

  /** Remote: Update documentation structure */
  async updateRemoteDocumentationStructure(
    designSystemId: string,
    versionId: string,
    model: DocumentationLegacyModel
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation"
    )

    await this.bridge.put(endpoint, model)
  }

  /** Remote: Updates a design system documentation slug */
  async updateRemoteDesignSystemDocumentationSlug(
    designSystem: DesignSystem,
    docUserSlug: string
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystem.id,
      // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      undefined
    )

    await this.bridge.put(endpoint, {
      meta: {
        name: designSystem.name,
        description: designSystem.description,
      },
      docUserSlug,
    })
  }

  /** Remote: Updates a design system's access mode and, if switching to invite-only, also the member list */
  async updateRemoteDesignSystemAccessModeAndMemberList(
    designSystemId: string,
    accessMode: DesignSystemAccessMode,
    retainUserIds: Array<string>,
    retainInviteIds: Array<string>
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "access-mode"
    )

    await this.bridge.put(endpoint, {
      accessMode,
      retain: {
        userIds: retainUserIds,
        inviteIds: retainInviteIds,
      },
    } satisfies DTODesignSystemUpdateAccessModeInput)
  }

  /** Remote: Updates a design system's member list */
  async updateRemoteDesignSystemMemberList(
    designSystemId: string,
    usersToInvite?: Array<DesignSystemUserInvitation>,
    invitesToInvite?: Array<DesignSystemPendingMemberInvite>,
    emailsToInvite?: Array<DesignSystemInvite>,
    invitesToUpdate?: Array<DesignSystemInviteUpdate>,
    deleteInvitationIds?: Array<string>,
    usersToUpdate?: Array<DesignSystemMemberUpdate>,
    removeUserIds?: Array<string>
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "members"
    )

    await this.bridge.post(endpoint, {
      usersToInvite,
      invitesToInvite,
      emailsToInvite,
      invitesToUpdate,
      deleteInvitationIds,
      usersToUpdate,
      removeUserIds,
    } satisfies DTODesignSystemMembersUpdatePayload)
  }

  /** Remote: Deletes a design system */
  async deleteDesignSystem(designSystemId: string): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(designSystemId, null)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Documentation

  async elementAction(
    designSystemId: string,
    versionId: string,
    data: DTOElementActionInput
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "elements-action"
    )

    return this.bridge.post(endpoint, data)
  }

  async createRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTOCreateDocumentationPageInputV2
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageCreate",
      input: data,
    })
  }

  async updateApprovalState(
    designSystemId: string,
    versionId: string,
    data: DTODocumentationPageApprovalStateChangeInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageApprovalStateChange",
      input: data,
    })
  }

  /** Remote: create a group within documentation */
  async createRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTOCreateDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupCreate",
      input: data,
    })
  }

  /** Remote: create a group within documentation */
  async createRemoteDocumentationTab(
    designSystemId: string,
    versionId: string,
    data: DTOCreateDocumentationTabInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationTabCreate",
      input: data,
    })
  }

  /** Remote: update a documentation page */
  async updateRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTOUpdateDocumentationPageInputV2
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageUpdate",
      input: data,
    })
  }

  /** Remote: updates a documentation group */
  async updateRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTOUpdateDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupUpdate",
      input: data,
    })
  }

  /** Remote: moves a documentation page */
  async moveRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTOMoveDocumentationPageInputV2
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageMove",
      input: data,
    })
  }

  /** Remote: moves a documentation group */
  async moveRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTOMoveDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupMove",
      input: data,
    })
  }

  /** Remote: creates a duplicate of a documentation page */
  async duplicateRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTODuplicateDocumentationPageInputV2
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageDuplicate",
      input: data,
    })
  }

  /** Remote: creates a duplicate of a documentation group */
  async duplicateRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTODuplicateDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupDuplicate",
      input: data,
    })
  }

  /** Remote: deletes a documentation page */
  async deleteRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTODeleteDocumentationPageInputV2
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageDelete",
      input: data,
    })
  }

  /** Remote: deletes a documentation tab group */
  async deleteRemoteDocumentationTabGroup(
    designSystemId: string,
    versionId: string,
    data: DTODeleteDocumentationTabGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationTabGroupDelete",
      input: data,
    })
  }

  async restoreRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTORestoreDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupRestore",
      input: data,
    })
  }

  async restoreRemoteDocumentationPage(
    designSystemId: string,
    versionId: string,
    data: DTORestoreDocumentationPageInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationPageRestore",
      input: data,
    })
  }

  /** Remote: Deletes a documentation group */
  async deleteRemoteDocumentationGroup(
    designSystemId: string,
    versionId: string,
    data: DTODeleteDocumentationGroupInput
  ) {
    return this.elementAction(designSystemId, versionId, {
      type: "DocumentationGroupDelete",
      input: data,
    })
  }

  async publishDocumentation(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment
  ): Promise<ExportBuildModel> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/jobs/documentation`
    )

    const payload = {
      designSystemId,
      designSystemVersionId: versionId,
      environment,
    }

    const response = await this.bridge.post(endpoint, payload)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.result.job as ExportBuildModel
  }

  async publishDocumentationDrafts(
    designSystemId: string,
    versionId: string,
    environment: DocumentationEnvironment,
    changes: DTOPublishDocumentationRequest["changes"]
  ): Promise<ExportBuildModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "documentation/publish"
    )

    const payload: DTOPublishDocumentationRequest = {
      environment,
      changes,
    }

    const response = await this.bridge.post(endpoint, payload)

    return response.result.job as ExportBuildModel
  }

  /** Remote: Render specific figma frames */
  async renderFigmaFrames(
    designSystemId: string,
    versionId: string,
    data: Array<DTOFigmaNodeRenderIdInput>
  ): Promise<Array<DTOFigmaNode>> {
    const payload = await this.elementAction(designSystemId, versionId, {
      type: "FigmaNodeRender",
      input: data,
    })

    return payload.result.figmaNodes
  }

  /** Remote: Render specific figma frames async */
  async renderFigmaFramesAsync(
    designSystemId: string,
    versionId: string,
    data: Array<DTOFigmaNodeRenderInput>
  ): Promise<Array<DTOFigmaNode>> {
    const payload = await this.elementAction(designSystemId, versionId, {
      type: "FigmaNodeRenderAsync",
      nodes: data,
    })

    return payload.result.figmaNodes
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Tokens

  async createRemoteToken(
    designSystemId: string,
    versionId: string,
    token: TokenRemoteModel
  ): Promise<TokenRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "tokens"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, token)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.token as TokenRemoteModel
  }

  async updateRemoteToken(
    designSystemId: string,
    versionId: string,
    token: TokenRemoteModel
  ): Promise<TokenRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `tokens/${token.id}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, token)
  }

  async createRemoteTokenGroup(
    designSystemId: string,
    versionId: string,
    group: TokenGroupRemoteModel
  ): Promise<TokenGroupRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "token-groups"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, group)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.group as TokenGroupRemoteModel
  }

  async updateRemoteTokenGroup(
    designSystemId: string,
    versionId: string,
    group: TokenGroupRemoteModel
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `token-groups/${group.id}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, group)
  }

  async deleteRemoteToken(
    designSystemId: string,
    versionId: string,
    tokenId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `tokens/${tokenId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async ungroupRemoteTokenGroup(
    designSystemId: string,
    versionId: string,
    tokenGroupId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `token-groups/${tokenGroupId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async createRemoteTokenTheme(
    designSystemId: string,
    versionId: string,
    theme: TokenThemeRemoteModel
  ): Promise<TokenThemeRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "themes"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, theme)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.theme as TokenThemeRemoteModel
  }

  async updateRemoteTokenTheme(
    designSystemId: string,
    versionId: string,
    group: TokenThemeRemoteModel
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `themes/${group.id}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, group)
  }

  async deleteRemoteTokenTheme(
    designSystemId: string,
    versionId: string,
    themeId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `themes/${themeId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async createThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    themeId: string,
    override: TokenThemeOverrideTranserObject
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `themes/${themeId}/overrides`
    )

    return this.bridge.post(endpoint, override)
  }

  async updateThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    tokenPersistentId: string,
    themeId: string,
    override: TokenThemeOverrideTranserObject
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `themes/${themeId}/overrides/${tokenPersistentId}`
    )

    return this.bridge.put(endpoint, override)
  }

  async deleteThemeOverrideForToken(
    designSystemId: string,
    versionId: string,
    tokenPersistentId: string,
    themeId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `themes/${themeId}/overrides/${tokenPersistentId}`
    )

    return this.bridge.delete(endpoint)
  }

  async writeTokenData(
    designSystemId: string,
    versionId: string,
    tokens: Array<TokenRemoteModel>,
    groups: Array<TokenGroupRemoteModel>,
    deleteTokens: Array<Token>,
    deleteTokenGroups: Array<TokenGroup>
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "bff/import"
    )

    const payload = {
      tokens,
      tokenGroups: groups,
      bulkDelete: {
        tokenIds: deleteTokens.map((t) => t.idInVersion),
        tokenGroupIds: deleteTokenGroups.map((t) => t.idInVersion),
      },
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, payload)
  }

  async writeTokenThemeData(
    designSystemId: string,
    versionId: string,
    theme: TokenThemeRemoteModel
  ): Promise<void> {
    const payload = theme

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `themes/${theme.id}`
    )

    await this.bridge.put(endpoint, payload)
  }

  async updateTokenPropertyValue(
    designSystemId: string,
    versionId: string,
    newValue: string | boolean | number,
    tokenId: string,
    propertyId: string
  ) {
    const payload = {
      value: newValue,
      definitionId: propertyId,
      targetElementId: tokenId,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/values`
    )

    await this.bridge.post(endpoint, payload)
  }

  async deleteTokenPropertyValue(
    designSystemId: string,
    versionId: string,
    valueId: string
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/values/${valueId}`
    )

    await this.bridge.delete(endpoint)
  }

  async createTokenProperty(
    designSystemId: string,
    versionId: string,
    model: object
  ): Promise<ElementPropertyRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "element-properties/definitions"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, model)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.definition as ElementPropertyRemoteModel
  }

  async updateTokenProperty(
    designSystemId: string,
    versionId: string,
    propertyIdInVersion: string,
    model: object
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/definitions/${propertyIdInVersion}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, model)
  }

  async deleteTokenProperty(
    designSystemId: string,
    versionId: string,
    propertyId: string
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/definitions/${propertyId}`
    )

    await this.bridge.delete(endpoint)
  }

  async updateReorderTokenColumn(
    designSystemId: string,
    versionId: string,
    viewId: string,
    columnId: string,
    newIndex: number
  ): Promise<void> {
    const payload = {
      newIndex,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-data-views/${viewId}/columns/${columnId}/reorder`
    )

    await this.bridge.put(endpoint, payload)
  }

  async updateResizeTokenColumn(
    designSystemId: string,
    versionId: string,
    viewId: string,
    columnType: ElementDataViewColumnType,
    columnId: string,
    columnThemeId: string | undefined,
    columnBasePropertyType: string | undefined,
    columnPropertyDefinitionId: string | undefined,
    newWidth: number
  ): Promise<void> {
    const payload = {
      type: columnType,
      width: newWidth,
      themeId: columnThemeId,
      basePropertyType: columnBasePropertyType,
      propertyDefinitionId: columnPropertyDefinitionId,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-data-views/${viewId}/columns/${columnId}`
    )

    await this.bridge.put(endpoint, payload)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Resources

  /** Remote: Upload a new asset resource */
  async uploadAssetResource(
    designSystemId: string,
    versionId: string,
    asset: File
  ): Promise<ResourceAssetModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "assets/image?scope=Documentation"
    )
    const formData = new FormData()

    formData.append("asset", asset)

    const result = await this.bridge.upload(endpoint, formData, "POST")
    return result.result.asset as ResourceAssetModel
  }

  /** Remote: Upload a new font resource */
  async uploadFontResource(
    designSystemId: string,
    versionId: string,
    font: File
  ): Promise<ResourceFontModel | null> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "assets/font?scope=DesignSystem"
    )
    const formData = new FormData()

    formData.append("asset", font)

    const result = await this.bridge.upload(endpoint, formData, "POST")

    if (result?.result?.assets?.[0]) {
      return result.result.assets[0] as ResourceFontModel
    }

    return null
  }

  /** Remote: Upload a new font resources */
  async uploadFontResources(
    designSystemId: string,
    versionId: string,
    fonts: FileList
  ): Promise<ResourceFontModel[]> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "assets/font?scope=DesignSystem"
    )
    const formData = new FormData()

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < fonts.length; i++) {
      const font = fonts.item(i)

      if (!font) {
        continue
      }

      formData.append("asset", font)
    }

    const result = await this.bridge.upload(endpoint, formData, "POST")

    return result.result.assets as ResourceFontModel[]
  }

  /** Remote: Delete a resource */
  async deleteResource(
    designSystemId: string,
    versionId: string,
    resourceId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `assets/${resourceId}`
    )

    return this.bridge.delete(endpoint)
  }

  /** Remote: Delete resources */
  async deleteResources(
    designSystemId: string,
    versionId: string,
    resourceIds: string[]
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `assets/batch-delete`
    )

    return this.bridge.post(endpoint, {
      ids: resourceIds,
    })
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Components

  async createRemoteComponent(
    designSystemId: string,
    versionId: string,
    component: ComponentRemoteModel
  ): Promise<ComponentRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "design-system-components"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, component)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.designSystemComponent as ComponentRemoteModel
  }

  async updateRemoteComponent(
    designSystemId: string,
    versionId: string,
    component: ComponentUpdateRemoteModel
  ): Promise<ComponentRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `design-system-components/${component.id}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, component)
  }

  async createRemoteComponentGroup(
    designSystemId: string,
    versionId: string,
    group: ComponentGroupRemoteModel
  ): Promise<ComponentGroupRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "design-system-component-groups"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, group)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.group as ComponentGroupRemoteModel
  }

  async updateRemoteComponentGroup(
    designSystemId: string,
    versionId: string,
    group: ComponentGroupRemoteModel
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `design-system-component-groups/${group.id}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, group)
  }

  async deleteRemoteComponent(
    designSystemId: string,
    versionId: string,
    componentId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `design-system-components/${componentId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async ungroupRemoteComponentGroup(
    designSystemId: string,
    versionId: string,
    componentGroupId: string
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `design-system-component-groups/${componentGroupId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async writeComponentData(
    designSystemId: string,
    versionId: string,
    components: Array<ComponentRemoteModel>,
    groups: Array<ComponentGroupRemoteModel>,
    deleteComponents: Array<Component>,
    deleteComponentGroups: Array<ComponentGroup>
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "bff/import"
    )

    const payload = {
      components,
      componentGroups: groups,
      bulkDelete: {
        componentIds: deleteComponents.map((t) => t.idInVersion),
        componentGroupIds: deleteComponentGroups.map((t) => t.idInVersion),
      },
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.post(endpoint, payload)
  }

  async updateComponentPropertyValue(
    designSystemId: string,
    versionId: string,
    newValue: string | boolean | number,
    componentId: string,
    propertyId: string
  ) {
    const payload = {
      value: newValue,
      definitionId: propertyId,
      targetElementId: componentId,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/values`
    )

    await this.bridge.post(endpoint, payload)
  }

  async deleteComponentPropertyValue(
    designSystemId: string,
    versionId: string,
    valueId: string
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/values/${valueId}`
    )

    await this.bridge.delete(endpoint)
  }

  async createComponentProperty(
    designSystemId: string,
    versionId: string,
    model: object
  ): Promise<ElementPropertyRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "element-properties/definitions"
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, model)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.definition as ElementPropertyRemoteModel
  }

  async updateComponentProperty(
    designSystemId: string,
    versionId: string,
    propertyIdInVersion: string,
    model: object
  ): Promise<void> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/definitions/${propertyIdInVersion}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.put(endpoint, model)
  }

  async deleteComponentProperty(
    designSystemId: string,
    versionId: string,
    propertyId: string
  ) {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-properties/definitions/${propertyId}`
    )

    await this.bridge.delete(endpoint)
  }

  async updateReorderComponentColumn(
    designSystemId: string,
    versionId: string,
    viewId: string,
    columnId: string,
    newIndex: number
  ): Promise<void> {
    const payload = {
      newIndex,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-data-views/${viewId}/columns/${columnId}/reorder`
    )

    await this.bridge.put(endpoint, payload)
  }

  async updateResizeComponentColumn(
    designSystemId: string,
    versionId: string,
    viewId: string,
    columnType: ElementDataViewColumnType,
    columnId: string,
    columnThemeId: string | undefined,
    columnBasePropertyType: string | undefined,
    columnPropertyDefinitionId: string | undefined,
    newWidth: number
  ): Promise<void> {
    const payload = {
      type: columnType,
      width: newWidth,
      themeId: columnThemeId,
      basePropertyType: columnBasePropertyType,
      propertyDefinitionId: columnPropertyDefinitionId,
    }

    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      `element-data-views/${viewId}/columns/${columnId}`
    )

    await this.bridge.put(endpoint, payload)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Integrations

  /** Remote: Deletes a OAuth integration */
  async deleteOAuthIntegration(
    userId: string,
    service: "figma" | ExporterProvider
  ): Promise<void> {
    const endpoint = this.bridge.getBaseEndpoint(
      `users/${userId}/integration/${service}`
    )

    await this.bridge.delete(endpoint)
  }

  /** Remote: Create Authentication Token */
  async createAuthenticationToken(name: string, scope?: string) {
    const endpoint = this.bridge.getBaseEndpoint(`auth/tokens`)

    return (await this.bridge.post(endpoint, { name, scope })).result
      .token as CreateAuthenticationTokenPayload
  }

  /** Remote: Delete Authentication Token */
  async deleteAuthenticationToken(id: string) {
    const endpoint = this.bridge.getBaseEndpoint(`auth/tokens/${id}`)
    return (await this.bridge.delete(endpoint))
      .result as AuthenticationTokenPayload
  }

  /** Remote: Create Personal Access token connection */
  async createPersonalAccessTokenConnection(
    service: ConnectionProviders,
    connection:
      | PersonalAccessTokenConnectionPayload
      | AzurePersonalAccessTokenConnectionPayload
  ) {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/${service}/connections`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (await this.bridge.post(endpoint, connection)).result
      .connection as ConnectionRemoteModel
  }

  /** Remote: Create Personal Access token credential for a workspace integration */
  async createRemoteWorkspaceIntegrationAccessToken(
    workspaceId: string,
    data: WorkspaceIntegrationAccessTokenPayload
  ) {
    const endpoint = this.bridge.getWorkspaceEndpoint(
      workspaceId,
      "integrations"
    )

    return (await this.bridge.post(endpoint, data))
      .result as WorkspaceIntegrationsRemoteModel
  }

  /** Remote: Delete credential integration connection from workspace */
  async deleteRemoteWorkspaceIntegrationCredential(
    workspaceId: string,
    integrationId: string,
    credentialId: string
  ) {
    const endpoint = this.bridge.getWorkspaceIntegrationCredentialEndpoint(
      workspaceId,
      integrationId,
      credentialId
    )

    return (await this.bridge.delete(endpoint))
      .result as WorkspaceIntegrationType
  }

  /** Remote: Delete a workspace integration */
  async deleteRemoteWorkspaceIntegration(
    workspaceId: string,
    integrationId: string
  ) {
    const endpoint = this.bridge.getWorkspaceIntegrationEndpoint(
      workspaceId,
      integrationId
    )

    return (await this.bridge.delete(endpoint))
      .result as WorkspaceIntegrationType
  }

  /** Remote: Create pipeline */
  async createRemotePipeline(
    workspaceId: string,
    payload: DTOPipelineCreateBody
  ) {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/pipelines`
    )

    return (await this.bridge.post(endpoint, payload)).result
      .pipeline as DTOPipeline
  }

  /** Remote: Update pipeline */
  async updateRemotePipeline(
    designSystemId: string,
    pipelineId: string,
    payload: DTOPipelineUpdateBody
  ) {
    const endpoint = this.bridge.getBaseEndpoint(
      `design-systems/${designSystemId}/pipelines/${pipelineId}`
    )

    return (await this.bridge.put(endpoint, payload)).result
      .pipeline as DTOPipeline
  }

  /** Remote: Run pipeline */
  async runRemotePipeline(
    workspaceId: string,
    designSystemVersionId: string,
    pipelineId: string
  ): Promise<ExportBuildModel> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/pipelines/${pipelineId}/trigger`
    )

    const response = await this.bridge.post(endpoint, {
      designSystemVersionId,
    })

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.result.job as ExportBuildModel
  }

  /** Remote: Delete pipeline */
  async deleteRemotePipeline(
    workspaceId: string,
    pipelineId: string
  ): Promise<void> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/schedules/${pipelineId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Exporters

  /** Remote: Create exporter */
  async createRemoteExporter(workspaceId: string, payload: NewExporterPayload) {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/exporters`
    )

    return (await this.bridge.post(endpoint, payload)).result.exporter
  }

  /** Remote: Updates an exporter */
  async updateRemoteExporter(
    workspaceId: string,
    exporterId: string,
    payload: NewExporterPayload
  ) {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/exporters/${exporterId}`
    )

    return this.bridge.put(endpoint, payload)
  }

  /** Remote: Deletes an exporter */
  async deleteRemoteExporter(workspaceId: string, exporterId: string) {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/exporters/${exporterId}`
    )

    return this.bridge.delete(endpoint)
  }

  /** Remote: Updates an exporter configuration property value */
  async updateRemoteExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string,
    propertyValue: any
  ) {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `exporter-properties/${exporterId}/${propertyKey}`
    )

    return this.bridge.put(endpoint, { value: propertyValue })
  }

  /** Remote: Deletes an exporter configuration property value */
  async deleteRemoteExporterConfigurationPropertyValue(
    designSystemId: string,
    exporterId: string,
    propertyKey: string
  ) {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `exporter-properties/${exporterId}/${propertyKey}`
    )

    return this.bridge.delete(endpoint)
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Builds

  /** Remote: Create a new exporter build */
  async createExporterBuild(
    workspaceId: string,
    designSystemId: string,
    versionId: string,
    exporterId: string,
    destinations: ExporterJobDestination,
    brandId: string | undefined,
    themePersistentIds: Array<string> | undefined,
    exporterConfigurationProperties: DTOExporterPropertyValueMap | undefined
  ): Promise<ExportBuildModel> {
    const endpoint = this.bridge.getBaseEndpoint(
      `codegen/workspaces/${workspaceId}/jobs`
    )

    const payload = {
      designSystemId,
      designSystemVersionId: versionId,
      exporterId,
      destinations,
      brandId,
      themePersistentIds,
      exporterPropertyValues: exporterConfigurationProperties,
    }

    const response = await this.bridge.post(endpoint, payload)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return response.result.job as ExportBuildModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Jobs

  /** Remote: Schedules import job */
  async scheduleImportJobForFigmaSources(
    designSystemId: string,
    versionId: string,
    sourceIds: Array<string>,
    dryRun: boolean
  ): Promise<ImportJobRemoteModel> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "cloud-import"
    )

    const data = {
      sourceIds,
      dryRun,
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, data)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.job as ImportJobRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Sources

  /** Remote: Deletes a remote data source */
  async deleteRemoteSource(
    designSystemId: string,
    sourceId: string
  ): Promise<void> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `sources/${sourceId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.bridge.delete(endpoint)
  }

  async linkFigmaSource(
    designSystemId: string,
    data: object
  ): Promise<SourceRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `sources`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.post(endpoint, data)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.source as SourceRemoteModel
  }

  async updateFigmaSource(
    designSystemId: string,
    sourceId: string,
    data: object
  ): Promise<SourceRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      `sources/${sourceId}`
    )

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const payload = await this.bridge.put(endpoint, data)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return payload.result.source as SourceRemoteModel
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Import

  async writeTokenStudioJSONData(
    designSystemId: string,
    versionId: string,
    data: object
  ): Promise<object> {
    const endpoint = this.bridge.getVersionEndpoint(
      designSystemId,
      versionId,
      "bff/token-studio"
    )

    const payload = this.bridge.post(endpoint, data)
    return payload
  }

  async importTokens(
    designSystemId: string,
    brandId: string,
    sourceId: string,
    sourceName: string,
    sourceType: "FigmaVariablesPlugin",
    importType: "Upload",
    payload: any,
    isTokenTypeSplitEnabled?: boolean
  ): Promise<ImportJobRemoteModel> {
    const endpoint = this.bridge.getDesignSystemEndpoint(
      designSystemId,
      "bff/import"
    )

    const data = {
      brandPersistentId: brandId,
      sourceName,
      remoteId: sourceId,
      remoteSourceType: sourceType,
      type: importType,
      ...(isTokenTypeSplitEnabled !== undefined && { isTokenTypeSplitEnabled }),
      // TODO:fix-sdk-eslint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      payload,
    }

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = await this.bridge.post(endpoint, data)

    // TODO:fix-sdk-eslint
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return response.result.job
  }

  // --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---
  // MARK: - Storage

  async setKeyValueStorageData(
    designSystemId: string,
    kvData: object
  ): Promise<void> {
    const endpoint = this.bridge.getBaseEndpoint(
      `design-systems/${designSystemId}/metadata`
    )

    await this.bridge.put(endpoint, kvData)
  }
}
